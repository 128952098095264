import React, { useEffect, useState } from "react";

import Logo from "../logo.png"; // ABI of your contract
import logoToken from "../logoToken.png"; // ABI of your contract
import addImg from "../add.png"; // ABI of your contract
import { useContract } from "../hooks/useContract";
import { ERC20_ABI } from "../abis/erc20";
import QtechStakingABI from "../abis/QtechStakingABI.json";
import NodesABI from "../abis/NodesABI.json";
import CollectionABI from "../abis/CollectionABI.json";
import SETTINGS from "../SETTINGS";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Alert,
  Card,
  ToggleButtonGroup,
  ToggleButton,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, formatUnits } from "ethers";
import Node from "../components/Node";
import UserVesting from "../components/UserVesting"; 
import EtherscanTransactions from "../components/EtherscanTransactions";
import { useNavigate } from "react-router-dom";
import copyImg from "../copy.png";
import boxImg from "../images/box.png";

function Dashboard({ provider, address, isConn }) {
  const { collections, ownedNFTs, fetchOwnedNFTs, totalRewards } =
    useContract(provider);

  const [usdtBalance, setUsdtBalance] = useState("0");
  const [refCode, setRefCode] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  const [refLoading, setRefLoading] = useState(false);
  const [qvrsStakes, setQvrsStakes] = useState([]);
  const [referredTokenIds, setReferredTokenIds] = useState([]);

  const [refArr, setRefArr] = useState([]);
  const trunAddress = `${SETTINGS.collection.slice(
    0,
    4
  )}...${SETTINGS.collection.slice(-1 * 4)}`;

  const refferals = [
    {
        "tokenId": 1,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 2,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 3,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 4,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 5,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 6,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 7,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 8,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 9,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 10,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 11,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 12,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 13,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 14,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 15,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 16,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 17,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 18,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 19,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 20,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 21,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 22,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 23,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 24,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 25,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 26,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 27,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 28,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 29,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 30,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 31,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 32,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 33,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 34,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 35,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 36,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 37,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 38,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 39,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 40,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 41,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 42,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 43,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 44,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 45,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 46,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 47,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 48,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 49,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 50,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 51,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 52,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 53,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 54,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 55,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 56,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 57,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 58,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 59,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 60,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 61,
        "owner": "0x9748a170531B9cE08c6302C133FF8760816c8EfA",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 62,
        "owner": "0x9748a170531B9cE08c6302C133FF8760816c8EfA",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 63,
        "owner": "0x49DD2a7E85e4486e2DB59C6Aa662E7e9c227691C",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 64,
        "owner": "0x49DD2a7E85e4486e2DB59C6Aa662E7e9c227691C",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 65,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 66,
        "owner": "0xc54eB4EE194c60520Da558B54C659593f3b05FF5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 67,
        "owner": "0xc54eB4EE194c60520Da558B54C659593f3b05FF5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 68,
        "owner": "0xc54eB4EE194c60520Da558B54C659593f3b05FF5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 69,
        "owner": "0xc54eB4EE194c60520Da558B54C659593f3b05FF5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 70,
        "owner": "0xc54eB4EE194c60520Da558B54C659593f3b05FF5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 71,
        "owner": "0xc54eB4EE194c60520Da558B54C659593f3b05FF5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 72,
        "owner": "0xD2Ae22f2Cc13aa28998E766dACdF68fC100893e1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 73,
        "owner": "0xD2Ae22f2Cc13aa28998E766dACdF68fC100893e1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 74,
        "owner": "0xD2Ae22f2Cc13aa28998E766dACdF68fC100893e1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 75,
        "owner": "0xD2Ae22f2Cc13aa28998E766dACdF68fC100893e1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 76,
        "owner": "0xD2Ae22f2Cc13aa28998E766dACdF68fC100893e1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 77,
        "owner": "0xD2Ae22f2Cc13aa28998E766dACdF68fC100893e1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 78,
        "owner": "0xD2Ae22f2Cc13aa28998E766dACdF68fC100893e1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 79,
        "owner": "0x84A98eDbddB4aae81cC18116B0C724C6722172fC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 80,
        "owner": "0x84A98eDbddB4aae81cC18116B0C724C6722172fC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 81,
        "owner": "0x84A98eDbddB4aae81cC18116B0C724C6722172fC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 82,
        "owner": "0x1C6BB1691cA006d31e074529e82d839C82852822",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 83,
        "owner": "0x1C6BB1691cA006d31e074529e82d839C82852822",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 84,
        "owner": "0x1C6BB1691cA006d31e074529e82d839C82852822",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 85,
        "owner": "0x1C6BB1691cA006d31e074529e82d839C82852822",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 86,
        "owner": "0x1C6BB1691cA006d31e074529e82d839C82852822",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 87,
        "owner": "0x1C6BB1691cA006d31e074529e82d839C82852822",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 88,
        "owner": "0x1C6BB1691cA006d31e074529e82d839C82852822",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 89,
        "owner": "0x1C6BB1691cA006d31e074529e82d839C82852822",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 90,
        "owner": "0x84A98eDbddB4aae81cC18116B0C724C6722172fC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 91,
        "owner": "0x84A98eDbddB4aae81cC18116B0C724C6722172fC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 92,
        "owner": "0x84A98eDbddB4aae81cC18116B0C724C6722172fC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 93,
        "owner": "0x84A98eDbddB4aae81cC18116B0C724C6722172fC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 94,
        "owner": "0x84A98eDbddB4aae81cC18116B0C724C6722172fC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 95,
        "owner": "0x84A98eDbddB4aae81cC18116B0C724C6722172fC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 96,
        "owner": "0x6552679f5D35Cf214F2eEf52379D8A789d42Dbea",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 97,
        "owner": "0xc54eB4EE194c60520Da558B54C659593f3b05FF5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 98,
        "owner": "0xc54eB4EE194c60520Da558B54C659593f3b05FF5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 99,
        "owner": "0xc54eB4EE194c60520Da558B54C659593f3b05FF5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 100,
        "owner": "0xc54eB4EE194c60520Da558B54C659593f3b05FF5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 101,
        "owner": "0xc380f8AB22fD74d3dC6D8B25B5d30AEdA6934915",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 102,
        "owner": "0xc380f8AB22fD74d3dC6D8B25B5d30AEdA6934915",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 103,
        "owner": "0xC627E1C3e0E585a9A3350f3962c6a04954Bc5108",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 104,
        "owner": "0xC627E1C3e0E585a9A3350f3962c6a04954Bc5108",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 105,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 106,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 107,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 108,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 109,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 110,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 111,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 112,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 113,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 114,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 115,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 116,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 117,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 118,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 119,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 120,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 121,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 122,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 123,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 124,
        "owner": "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 125,
        "owner": "0xC627E1C3e0E585a9A3350f3962c6a04954Bc5108",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 126,
        "owner": "0x13Eb3d960d61dE4A5c7888827ccE5C29D021b20C",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 127,
        "owner": "0xC627E1C3e0E585a9A3350f3962c6a04954Bc5108",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 128,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 129,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 130,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 131,
        "owner": "0xC627E1C3e0E585a9A3350f3962c6a04954Bc5108",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 132,
        "owner": "0x1F9b8540626d0AA81f75b22f7E45c64C760bd939",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 133,
        "owner": "0x83adfA34111354825519088d5fB41c30bc09664d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 134,
        "owner": "0xB72b81E750B2ef736ed7d49374D48c4a43e7529d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 135,
        "owner": "0xB72b81E750B2ef736ed7d49374D48c4a43e7529d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 136,
        "owner": "0x13Eb3d960d61dE4A5c7888827ccE5C29D021b20C",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 137,
        "owner": "0x007094658D5B370c60491A29639272559D04A4C8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 138,
        "owner": "0xC627E1C3e0E585a9A3350f3962c6a04954Bc5108",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 139,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 140,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 141,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 142,
        "owner": "0x08e4f09523Ae1AE5d552bb85abCD2728573153B6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 143,
        "owner": "0x08e4f09523Ae1AE5d552bb85abCD2728573153B6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 144,
        "owner": "0x08e4f09523Ae1AE5d552bb85abCD2728573153B6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 145,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 146,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 147,
        "owner": "0x39067D5149d36581e5B76E0169D6D357a12a51F5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 148,
        "owner": "0xb96c0E127850B88144AF169966f2BF175D091816",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 149,
        "owner": "0xC627E1C3e0E585a9A3350f3962c6a04954Bc5108",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 150,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 151,
        "owner": "0x542407B48C857cAc5D44680995D532D18685d5cc",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 152,
        "owner": "0x32853fEf935A2074B6E6942bd8f1fA994CE907e6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 153,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 154,
        "owner": "0x08e4f09523Ae1AE5d552bb85abCD2728573153B6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 155,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 156,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 157,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 158,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 159,
        "owner": "0x83ee35639270BB981579b8E34fcD3F07C6A3e357",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 160,
        "owner": "0x83ee35639270BB981579b8E34fcD3F07C6A3e357",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 161,
        "owner": "0x08e4f09523Ae1AE5d552bb85abCD2728573153B6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 162,
        "owner": "0x2A97D73E58bf9eC36369a70EDB891a2940582EAb",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 163,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 164,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 165,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 166,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 167,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 168,
        "owner": "0x08e4f09523Ae1AE5d552bb85abCD2728573153B6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 169,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 170,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 171,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 172,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 173,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 174,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 175,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 176,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 177,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 178,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 179,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 180,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 181,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 182,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 183,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 184,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 185,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 186,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 187,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 188,
        "owner": "0xC67BAC94B7532B5B4F604d27F95a095a2eFBee60",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 189,
        "owner": "0xD06ef5F40451CCB00F841ba3BbFF39fe7a108e93",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 190,
        "owner": "0xb1cd80D9f55C359eF359d020c94e4420535a1057",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 191,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 192,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 193,
        "owner": "0xec610D5A92b15ee88371Ed226a22996D7b992EfB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 194,
        "owner": "0xec610D5A92b15ee88371Ed226a22996D7b992EfB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 195,
        "owner": "0xec610D5A92b15ee88371Ed226a22996D7b992EfB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 196,
        "owner": "0xec610D5A92b15ee88371Ed226a22996D7b992EfB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 197,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 198,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 199,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 200,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 201,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 202,
        "owner": "0x5DDC03f3f13839D508038a9A2429d76706e7fD69",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 203,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 204,
        "owner": "0xec610D5A92b15ee88371Ed226a22996D7b992EfB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 205,
        "owner": "0xec610D5A92b15ee88371Ed226a22996D7b992EfB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 206,
        "owner": "0x66331934ace6fB4e0D71B98F694D1fE547FAA902",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 207,
        "owner": "0x2cbF9ae43dD737919a3922Dd094C08A00B258021",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 208,
        "owner": "0x46ebC36fCAbAC790CfA5C6729f17533686627704",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 209,
        "owner": "0x49C64de99A5EC4647F9DdC249f27F1526DA784F9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 210,
        "owner": "0x6014EA3b930Cd0cD2b8131A55a2b17f1229110B1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 211,
        "owner": "0xa89119C4D16C4A1cb9e202fDF354C1e708EE5f23",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 212,
        "owner": "0xec610D5A92b15ee88371Ed226a22996D7b992EfB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 213,
        "owner": "0xec610D5A92b15ee88371Ed226a22996D7b992EfB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 214,
        "owner": "0xec610D5A92b15ee88371Ed226a22996D7b992EfB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 215,
        "owner": "0xec610D5A92b15ee88371Ed226a22996D7b992EfB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 216,
        "owner": "0x2B69Eb2124Cb68162672c39e7909BB5C0313B922",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 217,
        "owner": "0x6De17A91f10646D3E6cdEec712f5272eC783c866",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 218,
        "owner": "0x9959bE121F4159E76eEf60ab9b854e423B8D707A",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 219,
        "owner": "0x3D50d9bBB84e72A75ff8ab2b2360be904Ec0B5d2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 220,
        "owner": "0xC627E1C3e0E585a9A3350f3962c6a04954Bc5108",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 221,
        "owner": "0x3344aBb06afd1dBa592e833ADD613E9941888F25",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 222,
        "owner": "0xc4fEF763ab1916c983B57448D3d7213624c018C4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 223,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 224,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 225,
        "owner": "0xBf47078055A00Ae4b07445c6B7b2dBd965041E55",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 226,
        "owner": "0x9959bE121F4159E76eEf60ab9b854e423B8D707A",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 227,
        "owner": "0x030c7Ae2Fb9D81519a042FC621768a75c58Bb50E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 228,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 229,
        "owner": "0x3c8d8F1AdbDE4F7711c9af33A803f6506dBD6EDB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 230,
        "owner": "0xCC7F7566767E3da00Cf66d18034605e6CFa944DF",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 231,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 232,
        "owner": "0x5B9c55bdd93241C4b4019d30aC9f65a4b44C23dC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 233,
        "owner": "0x20963aeB86FEd6691524140d08bDbC5a9650B1dB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 234,
        "owner": "0xE94FfBcCD16b9f6ce6819f78Cc2AF43516c1d855",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 235,
        "owner": "0xa1534E54A390dAd3e6EdA03891F114412E6b1c4A",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 236,
        "owner": "0x1f824b82463Bc3aD252206c2a2034D8f37d5E760",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 237,
        "owner": "0x5488ea01962EAAB5872C87DA5b9622A7Ab7D7C72",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 238,
        "owner": "0x6e498077680D1AE71B7295C6ceb0e1e8721125B7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 239,
        "owner": "0x6e498077680D1AE71B7295C6ceb0e1e8721125B7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 240,
        "owner": "0x6e498077680D1AE71B7295C6ceb0e1e8721125B7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 241,
        "owner": "0x6e498077680D1AE71B7295C6ceb0e1e8721125B7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 242,
        "owner": "0x6e498077680D1AE71B7295C6ceb0e1e8721125B7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 243,
        "owner": "0x6e498077680D1AE71B7295C6ceb0e1e8721125B7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 244,
        "owner": "0x6e498077680D1AE71B7295C6ceb0e1e8721125B7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 245,
        "owner": "0x20CE028c63d7ed79D1c6F697798524655e9e02BF",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 246,
        "owner": "0x33b9157ACDD53F73cE287227b167E034413FD31B",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 247,
        "owner": "0x33b9157ACDD53F73cE287227b167E034413FD31B",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 248,
        "owner": "0xa9C47a88833d93d76B5B980F3C8A9157A9498a5d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 249,
        "owner": "0xdeb545033c408BF0AE767d22098762fa97DC1F61",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 250,
        "owner": "0xF664a2fF97F83715b608BaF796B4B2cFBf9690e5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 251,
        "owner": "0xE9A169bEA6c93659FAdf2CdDfDc4659c7a395194",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 252,
        "owner": "0x6e498077680D1AE71B7295C6ceb0e1e8721125B7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 253,
        "owner": "0x6e498077680D1AE71B7295C6ceb0e1e8721125B7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 254,
        "owner": "0x6e498077680D1AE71B7295C6ceb0e1e8721125B7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 255,
        "owner": "0x8DEfB9B74490B3aABE7F7012E8B8a79D1B5d2179",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 256,
        "owner": "0xC799c2e33f8817562291c72F8a7bba865b346a09",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 257,
        "owner": "0x3ca7a9BbA4DCD9a511F6D9040F631Fbb03c2e153",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 258,
        "owner": "0xE20743eaD26d5B3bb471d08c1c45f48B1C34ebBD",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 259,
        "owner": "0x5D3465f780ceb1C2314dEC931b7018A2e27B4E43",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 260,
        "owner": "0x3e2Fb6Ecbb0cF9256833d9C297ab19C0058770C0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 261,
        "owner": "0x6427400fA20DEF69c63639a44302BdF23F5EAB12",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 262,
        "owner": "0x78446DEB325a0A6D24918373aD472dB21149585D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 263,
        "owner": "0x656Fe2d5b7c07596d88C6af6e62E5272D3812AB9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 264,
        "owner": "0x1716a39d3B6b25f18070680E4e163d08DC6F3Fb4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 265,
        "owner": "0x08eA1DEE6A6173d530a55531740e687Bc48C7c76",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 266,
        "owner": "0x772D720D8f5717e2Ac731CFb49dE2f7e82c86AB7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 267,
        "owner": "0x25D852Fa0bB2A842468AA7c9ef87B65850c6302e",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 268,
        "owner": "0xD0CD34b27A5d2D4E00C298bC3D6c06F7176eabBE",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 269,
        "owner": "0x460fD7fE5b939fd8BdBbe831380f3E378663183D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 270,
        "owner": "0xE5a4EeDF99Efa7880C87075a0B1Aaa378Cc8b206",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 271,
        "owner": "0x8d9c249DCA52f24024177BD389bc744Aa42300fc",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 272,
        "owner": "0x7E5916b06A467bA986C6B20e0E59803915fc5851",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 273,
        "owner": "0x8276790d1560D455e4f955927A98C8Eb7e114DbD",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 274,
        "owner": "0x035A46392Ad54493AEaFCa9E32Ee173f157DA4E1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 275,
        "owner": "0xfbf50D11760fa38150d2fEA4ae10D6b0de5F50E0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 276,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 277,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 278,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 279,
        "owner": "0xb4b7C10F7eA51917fF192472956C006d98D2d87A",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 280,
        "owner": "0x01538b6df6d883913c123DaA50C3F26d18295876",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 281,
        "owner": "0xFCB247023BC4179A01EBd878b029Aa19B6D7aDdc",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 282,
        "owner": "0xFCB247023BC4179A01EBd878b029Aa19B6D7aDdc",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 283,
        "owner": "0x7067F70f28c2cd6abBB16b492872Fc8E8c9Ae1EF",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 284,
        "owner": "0x3454262ec4C4a6B90CdfCd7235bFA49F43aa3Eb7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 285,
        "owner": "0xc39B3cC32e188bA7f0f20cb3b7b05dFc285f60f5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 286,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 287,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 288,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 289,
        "owner": "0x03575fd2089cfd5b1f284c02b157fFDC9DaadEb2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 290,
        "owner": "0x0032816CBab7ea0Fe57db9052F07430fc59B2Ca4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 291,
        "owner": "0xE04CbF593C306208e774c81dc466a53C5640245b",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 292,
        "owner": "0xfa4eE764B7F4d564840974957d8f38249658F01F",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 293,
        "owner": "0x747c78F46c90dAf860D6C093E669B10A7A52BC0a",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 294,
        "owner": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 295,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 296,
        "owner": "0xd78824b40FD786291e7FCcb025f8f0F288bB566a",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 297,
        "owner": "0x2751Dd3716F13Be8c8Df134139b925112D915a65",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 298,
        "owner": "0x9b2F07628d8Bfd5e81a5d7E636920a5f4CD622a4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 299,
        "owner": "0xE9EBb92a9777378112Ac0e17f945F06EC21810Db",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 300,
        "owner": "0xC4A3f0fa497E5cC4Ca55CB3Cf83BD15D81C13709",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 301,
        "owner": "0xDe16a174f13269aaFA98d675Bb3b6BC774aAE906",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 302,
        "owner": "0xf7eEFF2eB0F1e633C746B84f136F2f5Aa14101Cc",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 303,
        "owner": "0x4Dbd6733b0a98a4a69b0dEdf470A47CDA443ea41",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 304,
        "owner": "0x1bDC03dfE47E9D89Ef902ba6E9668d4f0e60bf06",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 305,
        "owner": "0xAFF95A7048bE933FDEC48658328249B38Ce82FAc",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 306,
        "owner": "0xe47A240317f06e3De9e963D4Ba98b438f60c657c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 307,
        "owner": "0xec610D5A92b15ee88371Ed226a22996D7b992EfB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 308,
        "owner": "0xE52f8c73c432578272E65a4E78c8B14452941E26",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 309,
        "owner": "0x3404Ac8c59f1C06C89a8979dBa25d48Cc6597C8B",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 310,
        "owner": "0xF8cC59f3af8d503d03B561C422A699967cCdAC3a",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 311,
        "owner": "0xD226c994c64a33e1Fc5eeFF840aD624D34967342",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 312,
        "owner": "0xB6a3F58B37B618DCde343C807D3BBC007dA5A1b3",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 313,
        "owner": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 314,
        "owner": "0x29d18f33ccB4DDB10B439B18E7Be8A02c7b12F16",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 315,
        "owner": "0x29d18f33ccB4DDB10B439B18E7Be8A02c7b12F16",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 316,
        "owner": "0x29d18f33ccB4DDB10B439B18E7Be8A02c7b12F16",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 317,
        "owner": "0xFC0a82F9B3640F95885B5E4bb05eCabFd2051c4A",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 318,
        "owner": "0x05A08634024FeDD637a20D50AE6A7B263400ca17",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 319,
        "owner": "0x1C9FCbdE7ff4Fe61a4D7D2F443C2aFcAdF567ba5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 320,
        "owner": "0xAe36926d8A2704b286cAc59F00ce26Db3C06d54A",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 321,
        "owner": "0xC799c2e33f8817562291c72F8a7bba865b346a09",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 322,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 323,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 324,
        "owner": "0x9A0AFC8E0919D1d281186f8418145f4D61068CA7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 325,
        "owner": "0x861C571d90006782a8694b65Bac3a3AF28FE2687",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 326,
        "owner": "0xA0b4a3Db47c03f65a363e80Fc10441a3038Aa0Bc",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 327,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 328,
        "owner": "0x747c78F46c90dAf860D6C093E669B10A7A52BC0a",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 329,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 330,
        "owner": "0x12280e7faaF878C6DcA6a02EFb62Ce4e8CDBC3Df",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 331,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 332,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 333,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 334,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 335,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 336,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 337,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 338,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 339,
        "owner": "0x0B12d4D06A73648E921e82dff75F8C73847A45f6",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 340,
        "owner": "0xF7DAD7B1d5c9163965BEA0b2b5fa857cc68B272c",
        "reff": "0x6014EA3b930Cd0cD2b8131A55a2b17f1229110B1"
    },
    {
        "tokenId": 341,
        "owner": "0xba9d62Df66373b55A99d7DAFA00068cAca8e873F",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 342,
        "owner": "0x83189952ff2E503Bd69f3D1C5E17c68f1cCe3ba5",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 343,
        "owner": "0xF6CF6A33572143Acb0182CfFED21eDb3Ff48fe15",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 344,
        "owner": "0x532f36834F10136e8cEb7bB17eE717143f2A8A31",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 345,
        "owner": "0x532f36834F10136e8cEb7bB17eE717143f2A8A31",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 346,
        "owner": "0xD06ef5F40451CCB00F841ba3BbFF39fe7a108e93",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 347,
        "owner": "0xD06ef5F40451CCB00F841ba3BbFF39fe7a108e93",
        "reff": "0x20963aeB86FEd6691524140d08bDbC5a9650B1dB"
    },
    {
        "tokenId": 348,
        "owner": "0xc59eDe407A183c30ac685305B27296490B48ac90",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 349,
        "owner": "0xD06ef5F40451CCB00F841ba3BbFF39fe7a108e93",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 350,
        "owner": "0xD06ef5F40451CCB00F841ba3BbFF39fe7a108e93",
        "reff": "0xdeb545033c408BF0AE767d22098762fa97DC1F61"
    },
    {
        "tokenId": 351,
        "owner": "0x4Dbd6733b0a98a4a69b0dEdf470A47CDA443ea41",
        "reff": "0x6014EA3b930Cd0cD2b8131A55a2b17f1229110B1"
    },
    {
        "tokenId": 352,
        "owner": "0x11DbBe0F8772E619DE73f04D6a3CbB96f59cB251",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 353,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 354,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 355,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 356,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 357,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 358,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 359,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 360,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 361,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 362,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 363,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 364,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 365,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 366,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 367,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x1716a39d3B6b25f18070680E4e163d08DC6F3Fb4"
    },
    {
        "tokenId": 368,
        "owner": "0xB482979B5D96dB465a1aa1ca9E24Cc5B6D3A553e",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 369,
        "owner": "0xE94FfBcCD16b9f6ce6819f78Cc2AF43516c1d855",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 370,
        "owner": "0xF5CaaA00EDc5dbAe8801B2740bc0F0af7516E7FE",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 371,
        "owner": "0xF5CaaA00EDc5dbAe8801B2740bc0F0af7516E7FE",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 372,
        "owner": "0xF5CaaA00EDc5dbAe8801B2740bc0F0af7516E7FE",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 373,
        "owner": "0xd02BcB593Df93380C2074EeCba26042426Cf0dd3",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 374,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 375,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 376,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 377,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 378,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 379,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 380,
        "owner": "0x7e7d79396043925f4680Df3392B08A64d80cE28e",
        "reff": "0x030c7Ae2Fb9D81519a042FC621768a75c58Bb50E"
    },
    {
        "tokenId": 381,
        "owner": "0xF39b1D846334b357Dd70A4c3C6874398832AD551",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 382,
        "owner": "0xd3bBbEfC769Ff87bc7220dA91f25F37BBb699847",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 383,
        "owner": "0xd3bBbEfC769Ff87bc7220dA91f25F37BBb699847",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 384,
        "owner": "0xd3bBbEfC769Ff87bc7220dA91f25F37BBb699847",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 385,
        "owner": "0xd319F8298fbAF0C625fBDaEA9De1722B627ac91B",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 386,
        "owner": "0xd3bBbEfC769Ff87bc7220dA91f25F37BBb699847",
        "reff": "0xdeb545033c408BF0AE767d22098762fa97DC1F61"
    },
    {
        "tokenId": 387,
        "owner": "0xE96653c7670A38D1D51D83921C7bDc9dD426E55E",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 388,
        "owner": "0x28209f08EAD20177f218f032931333b660Ac9da2",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 389,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x030c7Ae2Fb9D81519a042FC621768a75c58Bb50E"
    },
    {
        "tokenId": 390,
        "owner": "0x0b17AC117EBA795F8704Bc1239CBee5d8ca4BA89",
        "reff": "0x49C64de99A5EC4647F9DdC249f27F1526DA784F9"
    },
    {
        "tokenId": 391,
        "owner": "0xf7eEFF2eB0F1e633C746B84f136F2f5Aa14101Cc",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 392,
        "owner": "0xf7eEFF2eB0F1e633C746B84f136F2f5Aa14101Cc",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 393,
        "owner": "0xf7eEFF2eB0F1e633C746B84f136F2f5Aa14101Cc",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 394,
        "owner": "0xf7eEFF2eB0F1e633C746B84f136F2f5Aa14101Cc",
        "reff": "0x49C64de99A5EC4647F9DdC249f27F1526DA784F9"
    },
    {
        "tokenId": 395,
        "owner": "0xb3C50D111d5ce936754965fc854184F0d1159EE6",
        "reff": "0x49C64de99A5EC4647F9DdC249f27F1526DA784F9"
    },
    {
        "tokenId": 396,
        "owner": "0x9959bE121F4159E76eEf60ab9b854e423B8D707A",
        "reff": "0x0032816CBab7ea0Fe57db9052F07430fc59B2Ca4"
    },
    {
        "tokenId": 397,
        "owner": "0xEcE70C951CeC2948067A1e23975556382ef62f6d",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 398,
        "owner": "0xFa0DaA6eb68E2fDbB264600687438f07f167bf68",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 399,
        "owner": "0xFa0DaA6eb68E2fDbB264600687438f07f167bf68",
        "reff": "0x5DDC03f3f13839D508038a9A2429d76706e7fD69"
    },
    {
        "tokenId": 400,
        "owner": "0x3d0E245B9f85af6251be4425f8211f1253d8053B",
        "reff": "0x030c7Ae2Fb9D81519a042FC621768a75c58Bb50E"
    },
    {
        "tokenId": 401,
        "owner": "0x0F16185EfdF959640856FE6e037C2c730Eb5Fa09",
        "reff": "0xC02b76F4ac50426fac98ac18cc0B85A7137a58E4"
    },
    {
        "tokenId": 402,
        "owner": "0xC02b76F4ac50426fac98ac18cc0B85A7137a58E4",
        "reff": "0xdeb545033c408BF0AE767d22098762fa97DC1F61"
    },
    {
        "tokenId": 403,
        "owner": "0x4Dbd6733b0a98a4a69b0dEdf470A47CDA443ea41",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 404,
        "owner": "0x3f65a545ad70B4338Ef548E56C7392494519F1a7",
        "reff": "0x08eA1DEE6A6173d530a55531740e687Bc48C7c76"
    },
    {
        "tokenId": 405,
        "owner": "0xAdD695d9D243D3b76aaC58accbd1d111f5FA40C7",
        "reff": "0xAdD695d9D243D3b76aaC58accbd1d111f5FA40C7"
    },
    {
        "tokenId": 406,
        "owner": "0x9cF6724C32d56ACF4328CDF0542C19eFF65dEEd0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 407,
        "owner": "0x9cF6724C32d56ACF4328CDF0542C19eFF65dEEd0",
        "reff": "0xAdD695d9D243D3b76aaC58accbd1d111f5FA40C7"
    },
    {
        "tokenId": 408,
        "owner": "0x9cF6724C32d56ACF4328CDF0542C19eFF65dEEd0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 409,
        "owner": "0x9cF6724C32d56ACF4328CDF0542C19eFF65dEEd0",
        "reff": "0xAdD695d9D243D3b76aaC58accbd1d111f5FA40C7"
    },
    {
        "tokenId": 410,
        "owner": "0x3b9C692b31ae669Db6dfD97D02854C0De0534E99",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 411,
        "owner": "0x58611f153D1C312766Ff3b099836296894c4A221",
        "reff": "0xb3C50D111d5ce936754965fc854184F0d1159EE6"
    },
    {
        "tokenId": 412,
        "owner": "0x697A8904f9bE453e37CefF9BDB9eB0b93A46e39e",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 413,
        "owner": "0x90be80009204432CEC581f3Fb5eE67A866D2fdCb",
        "reff": "0xAdD695d9D243D3b76aaC58accbd1d111f5FA40C7"
    },
    {
        "tokenId": 414,
        "owner": "0xAE1D0b34a1ED43FFc8FE10b247eDBc5Fc3c26575",
        "reff": "0xdeb545033c408BF0AE767d22098762fa97DC1F61"
    },
    {
        "tokenId": 415,
        "owner": "0x8996A8c210d02AD6CEf89a4Bf26B3731AC1F7eDA",
        "reff": "0xBf47078055A00Ae4b07445c6B7b2dBd965041E55"
    },
    {
        "tokenId": 416,
        "owner": "0xC38e8126e04029A573AE3A918ABD7F7A0fcc9B52",
        "reff": "0x2cbF9ae43dD737919a3922Dd094C08A00B258021"
    },
    {
        "tokenId": 417,
        "owner": "0xBBD15cA420f07ddfd2B57f2E21e82a9EF0C6711D",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 418,
        "owner": "0xCE83e49aa04fD57bAE9a7b504556bCC6a7E5BE95",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 419,
        "owner": "0x5427c206697A4EAfEcf47c9396e0f098D6Aa196e",
        "reff": "0x2cbF9ae43dD737919a3922Dd094C08A00B258021"
    },
    {
        "tokenId": 420,
        "owner": "0x7c27A78958A85b3607076571F59BDF1A00E53f93",
        "reff": "0xf7eEFF2eB0F1e633C746B84f136F2f5Aa14101Cc"
    },
    {
        "tokenId": 421,
        "owner": "0x6b6A46F46FA95F4764c98346c3609123E0bD8910",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 422,
        "owner": "0x6b6A46F46FA95F4764c98346c3609123E0bD8910",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 423,
        "owner": "0x6b6A46F46FA95F4764c98346c3609123E0bD8910",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 424,
        "owner": "0x6b6A46F46FA95F4764c98346c3609123E0bD8910",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 425,
        "owner": "0x6b6A46F46FA95F4764c98346c3609123E0bD8910",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 426,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 427,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 428,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 429,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 430,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 431,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 432,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 433,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 434,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 435,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 436,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 437,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 438,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 439,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 440,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 441,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 442,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 443,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 444,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0xF39b1D846334b357Dd70A4c3C6874398832AD551"
    },
    {
        "tokenId": 445,
        "owner": "0xDbDCc03D0582ef4719f76F8ff117874281753e25",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 446,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 447,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0xBf47078055A00Ae4b07445c6B7b2dBd965041E55"
    },
    {
        "tokenId": 448,
        "owner": "0xc0780a61bfDA852e8FE8d0d9E967e1709A17C69F",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 449,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 450,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x49C64de99A5EC4647F9DdC249f27F1526DA784F9"
    },
    {
        "tokenId": 451,
        "owner": "0x112cbe9F0E6375870AF76dB15346B453340f5861",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 452,
        "owner": "0xd3bBbEfC769Ff87bc7220dA91f25F37BBb699847",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 453,
        "owner": "0xd3bBbEfC769Ff87bc7220dA91f25F37BBb699847",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 454,
        "owner": "0xd3bBbEfC769Ff87bc7220dA91f25F37BBb699847",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 455,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 456,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 457,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 458,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 459,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 460,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 461,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 462,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 463,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 464,
        "owner": "0x5D1Ca52B57dd6C295B9b1add77Db25589c670b87",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 465,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x772D720D8f5717e2Ac731CFb49dE2f7e82c86AB7"
    },
    {
        "tokenId": 466,
        "owner": "0x5E76fd59fb8B091Bd92d99bbf7C313E1Aba4a879",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 467,
        "owner": "0x4FDbbD20E7EeB7812aaaB7A3B0362c427d714360",
        "reff": "0x6014EA3b930Cd0cD2b8131A55a2b17f1229110B1"
    },
    {
        "tokenId": 468,
        "owner": "0xc835814872ba3B8C39f7f9d753071d99AB6A216c",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 469,
        "owner": "0x615e15d64d3a56675b794087A2006D830340e725",
        "reff": "0x1716a39d3B6b25f18070680E4e163d08DC6F3Fb4"
    },
    {
        "tokenId": 470,
        "owner": "0x9b2F07628d8Bfd5e81a5d7E636920a5f4CD622a4",
        "reff": "0xF39b1D846334b357Dd70A4c3C6874398832AD551"
    },
    {
        "tokenId": 471,
        "owner": "0xAD172730134F132173961269BE13C1C5e95C5Eb2",
        "reff": "0xC38e8126e04029A573AE3A918ABD7F7A0fcc9B52"
    },
    {
        "tokenId": 472,
        "owner": "0x8D0372867bfb4f4BE836e17bE2606BcBD6E6FB31",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 473,
        "owner": "0x3e8513AF843748bf3a2B144a24261C6CCF2976A6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 474,
        "owner": "0x3e8513AF843748bf3a2B144a24261C6CCF2976A6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 475,
        "owner": "0x3e8513AF843748bf3a2B144a24261C6CCF2976A6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 476,
        "owner": "0x3e8513AF843748bf3a2B144a24261C6CCF2976A6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 477,
        "owner": "0x3e8513AF843748bf3a2B144a24261C6CCF2976A6",
        "reff": "0x8d9c249DCA52f24024177BD389bc744Aa42300fc"
    },
    {
        "tokenId": 478,
        "owner": "0x8d9c249DCA52f24024177BD389bc744Aa42300fc",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 479,
        "owner": "0x0986A1D530d6A5852119F5D0461a8a1531b30599",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 480,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x49C64de99A5EC4647F9DdC249f27F1526DA784F9"
    },
    {
        "tokenId": 481,
        "owner": "0x9959bE121F4159E76eEf60ab9b854e423B8D707A",
        "reff": "0x1716a39d3B6b25f18070680E4e163d08DC6F3Fb4"
    },
    {
        "tokenId": 482,
        "owner": "0x44d826f9f12af6E2794Ca84b6690caF3c81cbc10",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 483,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 484,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 485,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 486,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0x5DDC03f3f13839D508038a9A2429d76706e7fD69"
    },
    {
        "tokenId": 487,
        "owner": "0x5DDC03f3f13839D508038a9A2429d76706e7fD69",
        "reff": "0x5DDC03f3f13839D508038a9A2429d76706e7fD69"
    },
    {
        "tokenId": 488,
        "owner": "0x5DDC03f3f13839D508038a9A2429d76706e7fD69",
        "reff": "0x0F16185EfdF959640856FE6e037C2c730Eb5Fa09"
    },
    {
        "tokenId": 489,
        "owner": "0x71ed04252092816E12fE11174df7921420B7cC5A",
        "reff": "0x49C64de99A5EC4647F9DdC249f27F1526DA784F9"
    },
    {
        "tokenId": 490,
        "owner": "0xFf7120a0889a301c0A12b92Bf62f2Fb81f9891Cc",
        "reff": "0x2cbF9ae43dD737919a3922Dd094C08A00B258021"
    },
    {
        "tokenId": 491,
        "owner": "0xc4fEF763ab1916c983B57448D3d7213624c018C4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 492,
        "owner": "0xc4fEF763ab1916c983B57448D3d7213624c018C4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 493,
        "owner": "0xc4fEF763ab1916c983B57448D3d7213624c018C4",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 494,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0x2751Dd3716F13Be8c8Df134139b925112D915a65"
    },
    {
        "tokenId": 495,
        "owner": "0x1D9d6BDe3c2fA7025E8f5E2aBaff9ca5A0D680fD",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 496,
        "owner": "0x5b9A328BDb32796f529238497120fCE2c6c9FcBD",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 497,
        "owner": "0x530C9869147B320Ae1Fc2064A34eB52d54924E29",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 498,
        "owner": "0x89884904e1412BAc81C91d2e35eA2AA51a482d84",
        "reff": "0x0B12d4D06A73648E921e82dff75F8C73847A45f6"
    },
    {
        "tokenId": 499,
        "owner": "0xFA7919F0f7e34D0c63b1B56224e5AB473eb16386",
        "reff": "0x030c7Ae2Fb9D81519a042FC621768a75c58Bb50E"
    },
    {
        "tokenId": 500,
        "owner": "0xF39b1D846334b357Dd70A4c3C6874398832AD551",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 501,
        "owner": "0x112cbe9F0E6375870AF76dB15346B453340f5861",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 502,
        "owner": "0x6Dc6afd4D83dc4773325f461Ec7C8E69147C5598",
        "reff": "0xfBeDA3ce92cc60958FC83C549AFE3b0B4dc03045"
    },
    {
        "tokenId": 503,
        "owner": "0xe5C5e52c9BD0aB745547e79f62d8C537d564373B",
        "reff": "0xfBeDA3ce92cc60958FC83C549AFE3b0B4dc03045"
    },
    {
        "tokenId": 504,
        "owner": "0x2265a73E416E74AEd9ff4dcb12591e51100ed680",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 505,
        "owner": "0x2265a73E416E74AEd9ff4dcb12591e51100ed680",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 506,
        "owner": "0x2265a73E416E74AEd9ff4dcb12591e51100ed680",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 507,
        "owner": "0x2265a73E416E74AEd9ff4dcb12591e51100ed680",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 508,
        "owner": "0x2265a73E416E74AEd9ff4dcb12591e51100ed680",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 509,
        "owner": "0x2265a73E416E74AEd9ff4dcb12591e51100ed680",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 510,
        "owner": "0x2265a73E416E74AEd9ff4dcb12591e51100ed680",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 511,
        "owner": "0x2265a73E416E74AEd9ff4dcb12591e51100ed680",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 512,
        "owner": "0x2265a73E416E74AEd9ff4dcb12591e51100ed680",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 513,
        "owner": "0x41aeB50AB799E5614bf28f68d9750C52ab8F5343",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 514,
        "owner": "0x65666d86b327575dcC80E781Cb80E030f337534E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 515,
        "owner": "0x65666d86b327575dcC80E781Cb80E030f337534E",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 516,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 517,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 518,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 519,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 520,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 521,
        "owner": "0x7673F22b7838F3e1B0961ca6FA66f865d09c7e10",
        "reff": "0x0032816CBab7ea0Fe57db9052F07430fc59B2Ca4"
    },
    {
        "tokenId": 522,
        "owner": "0xb6cAb14E6A75551D4E6363c460e7319A1A6537DF",
        "reff": "0xAD172730134F132173961269BE13C1C5e95C5Eb2"
    },
    {
        "tokenId": 523,
        "owner": "0x4caC0d916d3CDdD3942cAEea2Dee5cD293873D49",
        "reff": "0x4caC0d916d3CDdD3942cAEea2Dee5cD293873D49"
    },
    {
        "tokenId": 524,
        "owner": "0x7051EE1846Cad65Bc5765201b57B804adBEFD667",
        "reff": "0x4caC0d916d3CDdD3942cAEea2Dee5cD293873D49"
    },
    {
        "tokenId": 525,
        "owner": "0x1C1Fa5181Fb9687FF50bC009D055e50e84CAE7B7",
        "reff": "0xE04CbF593C306208e774c81dc466a53C5640245b"
    },
    {
        "tokenId": 526,
        "owner": "0x2F91f9459d5AFC0B2EfC8e2259971FBCcC77bC4B",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 527,
        "owner": "0x222E10A0160471a309D0B75B471CB0D0e6c50475",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 528,
        "owner": "0x222E10A0160471a309D0B75B471CB0D0e6c50475",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 529,
        "owner": "0x222E10A0160471a309D0B75B471CB0D0e6c50475",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 530,
        "owner": "0x222E10A0160471a309D0B75B471CB0D0e6c50475",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 531,
        "owner": "0x222E10A0160471a309D0B75B471CB0D0e6c50475",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 532,
        "owner": "0x222E10A0160471a309D0B75B471CB0D0e6c50475",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 533,
        "owner": "0x222E10A0160471a309D0B75B471CB0D0e6c50475",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 534,
        "owner": "0x222E10A0160471a309D0B75B471CB0D0e6c50475",
        "reff": "0x3404Ac8c59f1C06C89a8979dBa25d48Cc6597C8B"
    },
    {
        "tokenId": 535,
        "owner": "0xa655d6637eE61326180CFeC737853bF03E25f699",
        "reff": "0x0032816CBab7ea0Fe57db9052F07430fc59B2Ca4"
    },
    {
        "tokenId": 536,
        "owner": "0xEcE70C951CeC2948067A1e23975556382ef62f6d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 537,
        "owner": "0xEcE70C951CeC2948067A1e23975556382ef62f6d",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 538,
        "owner": "0xa54D5D80d3394D05cfc386CdD271c534F924e19E",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 539,
        "owner": "0xa54D5D80d3394D05cfc386CdD271c534F924e19E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 540,
        "owner": "0xa54D5D80d3394D05cfc386CdD271c534F924e19E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 541,
        "owner": "0xa54D5D80d3394D05cfc386CdD271c534F924e19E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 542,
        "owner": "0xa54D5D80d3394D05cfc386CdD271c534F924e19E",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 543,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 544,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 545,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 546,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 547,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x71ed04252092816E12fE11174df7921420B7cC5A"
    },
    {
        "tokenId": 548,
        "owner": "0x9338D889093c88f646eeC4c36fbc631b63229004",
        "reff": "0x9338D889093c88f646eeC4c36fbc631b63229004"
    },
    {
        "tokenId": 549,
        "owner": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4",
        "reff": "0x1716a39d3B6b25f18070680E4e163d08DC6F3Fb4"
    },
    {
        "tokenId": 550,
        "owner": "0xBe7A08A9054c1450C2f3e4615eCD7E9f0Ec54Af3",
        "reff": "0x13Eb3d960d61dE4A5c7888827ccE5C29D021b20C"
    },
    {
        "tokenId": 551,
        "owner": "0x13Eb3d960d61dE4A5c7888827ccE5C29D021b20C",
        "reff": "0x9338D889093c88f646eeC4c36fbc631b63229004"
    },
    {
        "tokenId": 552,
        "owner": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4",
        "reff": "0x9338D889093c88f646eeC4c36fbc631b63229004"
    },
    {
        "tokenId": 553,
        "owner": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 554,
        "owner": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 555,
        "owner": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4",
        "reff": "0x0032816CBab7ea0Fe57db9052F07430fc59B2Ca4"
    },
    {
        "tokenId": 556,
        "owner": "0x248D039796BC84e50Fcd0e7a02C1bf7bb8625a30",
        "reff": "0x08eA1DEE6A6173d530a55531740e687Bc48C7c76"
    },
    {
        "tokenId": 557,
        "owner": "0x4292333866EfeFCBA848e01289E1F01B8C6c6785",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 558,
        "owner": "0x4292333866EfeFCBA848e01289E1F01B8C6c6785",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 559,
        "owner": "0x4292333866EfeFCBA848e01289E1F01B8C6c6785",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 560,
        "owner": "0x4292333866EfeFCBA848e01289E1F01B8C6c6785",
        "reff": "0x08eA1DEE6A6173d530a55531740e687Bc48C7c76"
    },
    {
        "tokenId": 561,
        "owner": "0x4292333866EfeFCBA848e01289E1F01B8C6c6785",
        "reff": "0x248D039796BC84e50Fcd0e7a02C1bf7bb8625a30"
    },
    {
        "tokenId": 562,
        "owner": "0xa967da3b3CefeD637f3a921A50D8D01fA06D1205",
        "reff": "0xa967da3b3CefeD637f3a921A50D8D01fA06D1205"
    },
    {
        "tokenId": 563,
        "owner": "0xe0824Ff21bce0e42c0d48d8c2e18438aCfF40db7",
        "reff": "0x3344aBb06afd1dBa592e833ADD613E9941888F25"
    },
    {
        "tokenId": 564,
        "owner": "0x521A6A4FB5eE1B5909D121629d3E1b0A4b0be972",
        "reff": "0x71ed04252092816E12fE11174df7921420B7cC5A"
    },
    {
        "tokenId": 565,
        "owner": "0x4Bd37928e2C574b727B31143B24665e63B9d1020",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 566,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 567,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 568,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 569,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 570,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0xa967da3b3CefeD637f3a921A50D8D01fA06D1205"
    },
    {
        "tokenId": 571,
        "owner": "0xe0824Ff21bce0e42c0d48d8c2e18438aCfF40db7",
        "reff": "0xF39b1D846334b357Dd70A4c3C6874398832AD551"
    },
    {
        "tokenId": 572,
        "owner": "0xbAF59a327Eaa66e7c969E32E4312BB21b46ec2D6",
        "reff": "0x0B12d4D06A73648E921e82dff75F8C73847A45f6"
    },
    {
        "tokenId": 573,
        "owner": "0x27DB44B0a6050B08bC12C385Bc3bC996896a25B3",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 574,
        "owner": "0x74c39783Bcd9Ac2eA4caA4c28D0D8b92239F9E60",
        "reff": "0x20963aeB86FEd6691524140d08bDbC5a9650B1dB"
    },
    {
        "tokenId": 575,
        "owner": "0x8A3E707a035C6FCEa2c420ee8f2053A6fb3905f2",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 576,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 577,
        "owner": "0x573F8A18c8EAeB1Ecc26571e9C50ac8c840681C5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 578,
        "owner": "0x573F8A18c8EAeB1Ecc26571e9C50ac8c840681C5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 579,
        "owner": "0x573F8A18c8EAeB1Ecc26571e9C50ac8c840681C5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 580,
        "owner": "0x573F8A18c8EAeB1Ecc26571e9C50ac8c840681C5",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 581,
        "owner": "0x7550C7eeF4F0F48d3832e1cFf8ac920b8661Ca19",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 582,
        "owner": "0x7550C7eeF4F0F48d3832e1cFf8ac920b8661Ca19",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 583,
        "owner": "0x7550C7eeF4F0F48d3832e1cFf8ac920b8661Ca19",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 584,
        "owner": "0x7550C7eeF4F0F48d3832e1cFf8ac920b8661Ca19",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 585,
        "owner": "0xE40e9b4B98Ad2918A5646f134aC74C59AeeEFFE4",
        "reff": "0xBf47078055A00Ae4b07445c6B7b2dBd965041E55"
    },
    {
        "tokenId": 586,
        "owner": "0xb64887051c51535A6FC10053d4CfcE1B383c7b76",
        "reff": "0xa89119C4D16C4A1cb9e202fDF354C1e708EE5f23"
    },
    {
        "tokenId": 587,
        "owner": "0x9F99436A926682B54C97ddD07b7e7E71e1BF5eb5",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 588,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 589,
        "owner": "0x3E67ec480F4670bc306c7e8925B134CDe6EfDfcd",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 590,
        "owner": "0x3E67ec480F4670bc306c7e8925B134CDe6EfDfcd",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 591,
        "owner": "0x3E67ec480F4670bc306c7e8925B134CDe6EfDfcd",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 592,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 593,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 594,
        "owner": "0xDb1BbA1aD8CF9742E267FaDF685Ef25275e655f4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 595,
        "owner": "0xDb1BbA1aD8CF9742E267FaDF685Ef25275e655f4",
        "reff": "0x71ed04252092816E12fE11174df7921420B7cC5A"
    },
    {
        "tokenId": 596,
        "owner": "0x18b398280b1f10b9a069B8795ba7Ed30F674758a",
        "reff": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4"
    },
    {
        "tokenId": 597,
        "owner": "0xeC6D625EDf33697B21d8B26E7bb8a6F01cCd714c",
        "reff": "0x0B12d4D06A73648E921e82dff75F8C73847A45f6"
    },
    {
        "tokenId": 598,
        "owner": "0x15551a06F2b15cC1F812E6D65862Fbd3f8315e1E",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 599,
        "owner": "0xAbf899F0F08507B399a593E4de77Faeb3d22545c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 600,
        "owner": "0xAbf899F0F08507B399a593E4de77Faeb3d22545c",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 601,
        "owner": "0x388f234aa3DE4fe2c51937f9260aD5cCE2359B11",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 602,
        "owner": "0x369409169f878A907c9Dc4A75FB826B8a05Ce1E8",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 603,
        "owner": "0x8C99e45e3431bf4E1C568695e78Fd1cD4c8C9F3b",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 604,
        "owner": "0xF7d5dC13f8d124B31F152c9Ac1E46E0418faC782",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 605,
        "owner": "0xF7d5dC13f8d124B31F152c9Ac1E46E0418faC782",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 606,
        "owner": "0xDC19325128500FEA1616B86cFE631D877d1E34B7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 607,
        "owner": "0xDC19325128500FEA1616B86cFE631D877d1E34B7",
        "reff": "0x71ed04252092816E12fE11174df7921420B7cC5A"
    },
    {
        "tokenId": 608,
        "owner": "0x004D838e3f0469B0Cfd3cD4a4AF822c16f0c5059",
        "reff": "0x004D838e3f0469B0Cfd3cD4a4AF822c16f0c5059"
    },
    {
        "tokenId": 609,
        "owner": "0xB42a405df3A03b80Ea56FEEb43f9E7A2cAE889CE",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 610,
        "owner": "0x1288827F33E0B8c357C583fBC4814a22aD4D1358",
        "reff": "0xBf47078055A00Ae4b07445c6B7b2dBd965041E55"
    },
    {
        "tokenId": 611,
        "owner": "0xb64887051c51535A6FC10053d4CfcE1B383c7b76",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 612,
        "owner": "0x99708da786cF7cf6f6765DaE7D1e3757B6E9Ed5A",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 613,
        "owner": "0xb0bE519A316Cd680E137a12e0EdDD3145De60942",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 614,
        "owner": "0xb0bE519A316Cd680E137a12e0EdDD3145De60942",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 615,
        "owner": "0xb0bE519A316Cd680E137a12e0EdDD3145De60942",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 616,
        "owner": "0x29d18f33ccB4DDB10B439B18E7Be8A02c7b12F16",
        "reff": "0x3c8d8F1AdbDE4F7711c9af33A803f6506dBD6EDB"
    },
    {
        "tokenId": 617,
        "owner": "0x9018879682fBdF32796103101b954b14BA15CA66",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 618,
        "owner": "0x3007032407Aa11E7D0eA4072eA257F5C0e6B9C5e",
        "reff": "0x8738a55996385aC9Ee0C51eDb9564eE6038ef4C4"
    },
    {
        "tokenId": 619,
        "owner": "0xEf1a214f20cFE47bD674D6b5bd3a540d47cC586d",
        "reff": "0x3c8d8F1AdbDE4F7711c9af33A803f6506dBD6EDB"
    },
    {
        "tokenId": 620,
        "owner": "0xA83f9C5642c8eD43A1d44B7D40874b1A265EE0E9",
        "reff": "0x1f824b82463Bc3aD252206c2a2034D8f37d5E760"
    },
    {
        "tokenId": 621,
        "owner": "0x23f099E086BF0C774E8F35309c1e1E299b7e3c98",
        "reff": "0x3c8d8F1AdbDE4F7711c9af33A803f6506dBD6EDB"
    },
    {
        "tokenId": 622,
        "owner": "0xcf2947EF3C683922F254E0B7a5815379322CF6C8",
        "reff": "0x1f824b82463Bc3aD252206c2a2034D8f37d5E760"
    },
    {
        "tokenId": 623,
        "owner": "0x8738a55996385aC9Ee0C51eDb9564eE6038ef4C4",
        "reff": "0x9F99436A926682B54C97ddD07b7e7E71e1BF5eb5"
    },
    {
        "tokenId": 624,
        "owner": "0xE1e74C832B46C609682C096F9e6A0615AC43Bb66",
        "reff": "0xA83f9C5642c8eD43A1d44B7D40874b1A265EE0E9"
    },
    {
        "tokenId": 625,
        "owner": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51",
        "reff": "0xFf7120a0889a301c0A12b92Bf62f2Fb81f9891Cc"
    },
    {
        "tokenId": 626,
        "owner": "0xD9782E4dAd11c109ce5F09DdE9ACB098030B336a",
        "reff": "0xFf7120a0889a301c0A12b92Bf62f2Fb81f9891Cc"
    },
    {
        "tokenId": 627,
        "owner": "0x423c362374Fb0588A77105b906c286105329BBae",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 628,
        "owner": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7",
        "reff": "0xE1e74C832B46C609682C096F9e6A0615AC43Bb66"
    },
    {
        "tokenId": 629,
        "owner": "0x87a5b0638031DccbD93D4ae11773e6c7776175a7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 630,
        "owner": "0x87a5b0638031DccbD93D4ae11773e6c7776175a7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 631,
        "owner": "0x87a5b0638031DccbD93D4ae11773e6c7776175a7",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 632,
        "owner": "0x8666521c7Ac1C294A9a7B82d996E7f0888E9A66A",
        "reff": "0x248D039796BC84e50Fcd0e7a02C1bf7bb8625a30"
    },
    {
        "tokenId": 633,
        "owner": "0x3B9b6F04907605D1212aa9EB969f9Ada2e5f9CFF",
        "reff": "0xE1e74C832B46C609682C096F9e6A0615AC43Bb66"
    },
    {
        "tokenId": 634,
        "owner": "0x250FF6D8719543A166F73B415C6a0F4485946c77",
        "reff": "0xC38e8126e04029A573AE3A918ABD7F7A0fcc9B52"
    },
    {
        "tokenId": 635,
        "owner": "0x2e1dC30f1160bD9C6040DCDdD720f130ADb4A5d4",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 636,
        "owner": "0xAbf899F0F08507B399a593E4de77Faeb3d22545c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 637,
        "owner": "0xAbf899F0F08507B399a593E4de77Faeb3d22545c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 638,
        "owner": "0xAbf899F0F08507B399a593E4de77Faeb3d22545c",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 639,
        "owner": "0xbC342f21149DD6104F98390d0B2d00052a5490eF",
        "reff": "0xb3C50D111d5ce936754965fc854184F0d1159EE6"
    },
    {
        "tokenId": 640,
        "owner": "0x47011B76EBe1e0E243bb44CFa16583f77208c977",
        "reff": "0xA83f9C5642c8eD43A1d44B7D40874b1A265EE0E9"
    },
    {
        "tokenId": 641,
        "owner": "0xc05A2a4D08314C9E0B58788B96a9B8E59F007BFD",
        "reff": "0xF7d5dC13f8d124B31F152c9Ac1E46E0418faC782"
    },
    {
        "tokenId": 642,
        "owner": "0xA784916976D28381eBAd67Aa9792376aCe2b2660",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 643,
        "owner": "0x1642Fad86F18AFD1A49dC04631ce93c43E5694Bc",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 644,
        "owner": "0xb0bE519A316Cd680E137a12e0EdDD3145De60942",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 645,
        "owner": "0xb0bE519A316Cd680E137a12e0EdDD3145De60942",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 646,
        "owner": "0xb0bE519A316Cd680E137a12e0EdDD3145De60942",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 647,
        "owner": "0x7550C7eeF4F0F48d3832e1cFf8ac920b8661Ca19",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 648,
        "owner": "0x7550C7eeF4F0F48d3832e1cFf8ac920b8661Ca19",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 649,
        "owner": "0x222E10A0160471a309D0B75B471CB0D0e6c50475",
        "reff": "0x89884904e1412BAc81C91d2e35eA2AA51a482d84"
    },
    {
        "tokenId": 650,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 651,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 652,
        "owner": "0x1dd8Fae8c16EEeA1d293a5De8D27b7696531aB9d",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 653,
        "owner": "0x1dd8Fae8c16EEeA1d293a5De8D27b7696531aB9d",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 654,
        "owner": "0x3d7AF28E4e2Fe8063dEF96b13CaF86758cfC1D9c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 655,
        "owner": "0x3d7AF28E4e2Fe8063dEF96b13CaF86758cfC1D9c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 656,
        "owner": "0x3d7AF28E4e2Fe8063dEF96b13CaF86758cfC1D9c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 657,
        "owner": "0x3d7AF28E4e2Fe8063dEF96b13CaF86758cfC1D9c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 658,
        "owner": "0x3d7AF28E4e2Fe8063dEF96b13CaF86758cfC1D9c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 659,
        "owner": "0x3d7AF28E4e2Fe8063dEF96b13CaF86758cfC1D9c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 660,
        "owner": "0x3d7AF28E4e2Fe8063dEF96b13CaF86758cfC1D9c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 661,
        "owner": "0x3d7AF28E4e2Fe8063dEF96b13CaF86758cfC1D9c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 662,
        "owner": "0x3d7AF28E4e2Fe8063dEF96b13CaF86758cfC1D9c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 663,
        "owner": "0x3d7AF28E4e2Fe8063dEF96b13CaF86758cfC1D9c",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 664,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 665,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4"
    },
    {
        "tokenId": 666,
        "owner": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 667,
        "owner": "0xf1f4b649049aBeE50E7Aa4Ef17841CAeC22a1F68",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 668,
        "owner": "0xf1f4b649049aBeE50E7Aa4Ef17841CAeC22a1F68",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 669,
        "owner": "0xf1f4b649049aBeE50E7Aa4Ef17841CAeC22a1F68",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 670,
        "owner": "0xf1f4b649049aBeE50E7Aa4Ef17841CAeC22a1F68",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 671,
        "owner": "0xf1f4b649049aBeE50E7Aa4Ef17841CAeC22a1F68",
        "reff": "0x8A3E707a035C6FCEa2c420ee8f2053A6fb3905f2"
    },
    {
        "tokenId": 672,
        "owner": "0x89670214413cE3958D4de7aAEBce46bDc9583faa",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 673,
        "owner": "0x0BCB100C32aCA07Be7166Ac84C66aB06aEf5Bd88",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 674,
        "owner": "0x963c526Aa61445Eb5d0F33E53B37a042E92bACeA",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 675,
        "owner": "0x963c526Aa61445Eb5d0F33E53B37a042E92bACeA",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 676,
        "owner": "0x963c526Aa61445Eb5d0F33E53B37a042E92bACeA",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 677,
        "owner": "0x963c526Aa61445Eb5d0F33E53B37a042E92bACeA",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 678,
        "owner": "0x6Dc6afd4D83dc4773325f461Ec7C8E69147C5598",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 679,
        "owner": "0x6Dc6afd4D83dc4773325f461Ec7C8E69147C5598",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 680,
        "owner": "0x6Dc6afd4D83dc4773325f461Ec7C8E69147C5598",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 681,
        "owner": "0x6Dc6afd4D83dc4773325f461Ec7C8E69147C5598",
        "reff": "0x21F04028A270303e1773Caf7F587B42b4a0e6555"
    },
    {
        "tokenId": 682,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 683,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x89670214413cE3958D4de7aAEBce46bDc9583faa"
    },
    {
        "tokenId": 684,
        "owner": "0xdE8B28BBD2B6d436D5144F0bEe325dec920a65e8",
        "reff": "0x21F04028A270303e1773Caf7F587B42b4a0e6555"
    },
    {
        "tokenId": 685,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0xdE8B28BBD2B6d436D5144F0bEe325dec920a65e8"
    },
    {
        "tokenId": 686,
        "owner": "0xD95f6082506154f58B6034DC9ACA14C0369494b6",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 687,
        "owner": "0x573F8A18c8EAeB1Ecc26571e9C50ac8c840681C5",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 688,
        "owner": "0x573F8A18c8EAeB1Ecc26571e9C50ac8c840681C5",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 689,
        "owner": "0x222E10A0160471a309D0B75B471CB0D0e6c50475",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 690,
        "owner": "0xacDa8b6120e221a1C4dd288A88Ed9E6c88247649",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 691,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 692,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 693,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 694,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 695,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 696,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 697,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 698,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 699,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 700,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 701,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 702,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 703,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 704,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 705,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 706,
        "owner": "0x8B74cFcE0b75B35766025350657bA04eB7c44d0D",
        "reff": "0xfBeDA3ce92cc60958FC83C549AFE3b0B4dc03045"
    },
    {
        "tokenId": 707,
        "owner": "0xfE40bcE83d5C6e46872408DE188fFC308E3204D7",
        "reff": "0xBf47078055A00Ae4b07445c6B7b2dBd965041E55"
    },
    {
        "tokenId": 708,
        "owner": "0xB58eE84ADB3a6D0C0805F5eF4723BA496d054ddc",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 709,
        "owner": "0x460422e68f8C53bc712ec3A5409B2C5EEdE28671",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 710,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 711,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0xacDa8b6120e221a1C4dd288A88Ed9E6c88247649"
    },
    {
        "tokenId": 712,
        "owner": "0xbF267c8Fe8906028a9c36966A6FCbBd993120502",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 713,
        "owner": "0x8a850dB405aDD4B02EA5Dd882F3f7EAc254acc92",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 714,
        "owner": "0x162670a4438697fA9932B7c5dF520c1339DF40bF",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 715,
        "owner": "0x162670a4438697fA9932B7c5dF520c1339DF40bF",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 716,
        "owner": "0x162670a4438697fA9932B7c5dF520c1339DF40bF",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 717,
        "owner": "0x162670a4438697fA9932B7c5dF520c1339DF40bF",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 718,
        "owner": "0x4f646A93Ba2eC11cF61620461af5bD1E1A9d829A",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 719,
        "owner": "0x4f646A93Ba2eC11cF61620461af5bD1E1A9d829A",
        "reff": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d"
    },
    {
        "tokenId": 720,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 721,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 722,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 723,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 724,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 725,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 726,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 727,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 728,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 729,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 730,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 731,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 732,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 733,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 734,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 735,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x21F04028A270303e1773Caf7F587B42b4a0e6555"
    },
    {
        "tokenId": 736,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 737,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 738,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 739,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 740,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 741,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 742,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 743,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 744,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 745,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 746,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 747,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 748,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 749,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 750,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 751,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 752,
        "owner": "0xddA44977D99F39A85948f22B7BAE140402d4f7AA",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 753,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 754,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 755,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 756,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 757,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 758,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 759,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 760,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 761,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 762,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 763,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 764,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 765,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 766,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 767,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 768,
        "owner": "0x7a76521c8Cf0D767f5A42c455A1307ad60CCa0d1",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 769,
        "owner": "0x6fcEb88c2205212D771fBEF8Bbc480FE244de240",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 770,
        "owner": "0x1dd8Fae8c16EEeA1d293a5De8D27b7696531aB9d",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 771,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 772,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 773,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 774,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 775,
        "owner": "0x87a4A26f7d20A891be74e9Ad944550e0bc92CdEe",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 776,
        "owner": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 777,
        "owner": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 778,
        "owner": "0xf7123E06fd20940E7E7D0159B9B3B68df303a223",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 779,
        "owner": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C",
        "reff": "0xacDa8b6120e221a1C4dd288A88Ed9E6c88247649"
    },
    {
        "tokenId": 780,
        "owner": "0xC6d0d9d899e10dA17218EA671939219094Fcf220",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 781,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 782,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 783,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 784,
        "owner": "0x1df7bfAC3aC3D5673FC3a738A8b5e8B5CDa789F3",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 785,
        "owner": "0x1df7bfAC3aC3D5673FC3a738A8b5e8B5CDa789F3",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 786,
        "owner": "0xAbf899F0F08507B399a593E4de77Faeb3d22545c",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 787,
        "owner": "0xAbf899F0F08507B399a593E4de77Faeb3d22545c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 788,
        "owner": "0xAbf899F0F08507B399a593E4de77Faeb3d22545c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 789,
        "owner": "0xAbf899F0F08507B399a593E4de77Faeb3d22545c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 790,
        "owner": "0xAbf899F0F08507B399a593E4de77Faeb3d22545c",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 791,
        "owner": "0x83ee35639270BB981579b8E34fcD3F07C6A3e357",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 792,
        "owner": "0x83ee35639270BB981579b8E34fcD3F07C6A3e357",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 793,
        "owner": "0x83ee35639270BB981579b8E34fcD3F07C6A3e357",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 794,
        "owner": "0x83ee35639270BB981579b8E34fcD3F07C6A3e357",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 795,
        "owner": "0x433556c39a3aB56E1E1C05dD39D0F44cEF260228",
        "reff": "0xa54D5D80d3394D05cfc386CdD271c534F924e19E"
    },
    {
        "tokenId": 796,
        "owner": "0x991Df88a1d5Dc1d06713bC6467741bEbe77aDAF9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 797,
        "owner": "0x991Df88a1d5Dc1d06713bC6467741bEbe77aDAF9",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 798,
        "owner": "0xa8C16faaaBeFBF698dDC0405923ce032b134609e",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 799,
        "owner": "0xa8C16faaaBeFBF698dDC0405923ce032b134609e",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 800,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 801,
        "owner": "0x91FA7EE160796caf27D0dDbe9ee87A8198692692",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 802,
        "owner": "0x793f4D38EE259e897c6B5Cedcf743296978C267E",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 803,
        "owner": "0x91FA7EE160796caf27D0dDbe9ee87A8198692692",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 804,
        "owner": "0xdE48839D95BfCd53e07a72f6d32F23225447AC0a",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 805,
        "owner": "0xfc955017C700BEb22640A69114B56548d493e1C8",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 806,
        "owner": "0x3A9e914Cef893d9eCe79D054C2e2E723Cd2e269a",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 807,
        "owner": "0x0336d56593B0C2d23220048ed528Ebd0e5BF5dBB",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 808,
        "owner": "0xcf2947EF3C683922F254E0B7a5815379322CF6C8",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 809,
        "owner": "0x0bb83d98E0111AA36536d56cF4f50ca5Ccd5c387",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 810,
        "owner": "0x6A5c8CA6fd36121D80EbC4ada6956106c6d02EC8",
        "reff": "0x3c8d8F1AdbDE4F7711c9af33A803f6506dBD6EDB"
    },
    {
        "tokenId": 811,
        "owner": "0xb74B4e7d886809C517e5c3e6dd30daAB1983f71C",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 812,
        "owner": "0x6316B2bfb17E902817aC05A66b9c84DC0dFE66B2",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 813,
        "owner": "0xD032750a8Cc53E0dEBBC8080A03d85FC71ed90aa",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 814,
        "owner": "0xD032750a8Cc53E0dEBBC8080A03d85FC71ed90aa",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 815,
        "owner": "0xD032750a8Cc53E0dEBBC8080A03d85FC71ed90aa",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 816,
        "owner": "0xD032750a8Cc53E0dEBBC8080A03d85FC71ed90aa",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 817,
        "owner": "0x9F208866CB79A3249B3B5021d39AA8acCE8E5F0e",
        "reff": "0x89670214413cE3958D4de7aAEBce46bDc9583faa"
    },
    {
        "tokenId": 818,
        "owner": "0x97894eD238e61C72eC7DBdC001eEf9A0001DaCc3",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 819,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 820,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 821,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 822,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 823,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 824,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 825,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 826,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 827,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 828,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 829,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 830,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 831,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 832,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 833,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 834,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 835,
        "owner": "0xE9835F1cf5593FbEF1Bf4688F0603112E5695838",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 836,
        "owner": "0xE9835F1cf5593FbEF1Bf4688F0603112E5695838",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 837,
        "owner": "0xE9835F1cf5593FbEF1Bf4688F0603112E5695838",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 838,
        "owner": "0xE9835F1cf5593FbEF1Bf4688F0603112E5695838",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 839,
        "owner": "0x65666d86b327575dcC80E781Cb80E030f337534E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 840,
        "owner": "0x65666d86b327575dcC80E781Cb80E030f337534E",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 841,
        "owner": "0x09EC33DdbD96997ff8b98B84dc2A8b05404E6e63",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 842,
        "owner": "0x09EC33DdbD96997ff8b98B84dc2A8b05404E6e63",
        "reff": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4"
    },
    {
        "tokenId": 843,
        "owner": "0xbafD99BD4a4d2a5A62F2Db250C4EB4de674b0cca",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 844,
        "owner": "0x786A7020b56ef148a9A5cD9C84D74742E2626028",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 845,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 846,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0xDd87261c355dBDc93cab9A893A4242b3239faA4e"
    },
    {
        "tokenId": 847,
        "owner": "0xC67c7B38D0b211fb9892721B3B307d36762691ee",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 848,
        "owner": "0xe7FF70C9557506D7b1193e57C6fa3D2ebDd5cFA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 849,
        "owner": "0xe7FF70C9557506D7b1193e57C6fa3D2ebDd5cFA9",
        "reff": "0x1df7bfAC3aC3D5673FC3a738A8b5e8B5CDa789F3"
    },
    {
        "tokenId": 850,
        "owner": "0xf650C79265c03E222377849e2e228bBA5f970139",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 851,
        "owner": "0xD032750a8Cc53E0dEBBC8080A03d85FC71ed90aa",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 852,
        "owner": "0xD032750a8Cc53E0dEBBC8080A03d85FC71ed90aa",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 853,
        "owner": "0xD032750a8Cc53E0dEBBC8080A03d85FC71ed90aa",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 854,
        "owner": "0x9d019A64fB23A1492BD44A251ddf39E329309658",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 855,
        "owner": "0xA259529B27b3a4fD22B13f26BBc94FFbfF6F91B4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 856,
        "owner": "0xA259529B27b3a4fD22B13f26BBc94FFbfF6F91B4",
        "reff": "0xcc861253c7b0bB35350c4F58A5444959c0Cc616D"
    },
    {
        "tokenId": 857,
        "owner": "0xcc861253c7b0bB35350c4F58A5444959c0Cc616D",
        "reff": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3"
    },
    {
        "tokenId": 858,
        "owner": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3",
        "reff": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51"
    },
    {
        "tokenId": 859,
        "owner": "0x71861D1F995e1d506B277032Ef2480DaF6D346c1",
        "reff": "0x5ACd59237808047705f1992B1754390D376557e8"
    },
    {
        "tokenId": 860,
        "owner": "0x5ACd59237808047705f1992B1754390D376557e8",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 861,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 862,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 863,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 864,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 865,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 866,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 867,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 868,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 869,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 870,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 871,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 872,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 873,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 874,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 875,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 876,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 877,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 878,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 879,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 880,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 881,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 882,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 883,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 884,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 885,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 886,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 887,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 888,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 889,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 890,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 891,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 892,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8"
    },
    {
        "tokenId": 893,
        "owner": "0xE9835F1cf5593FbEF1Bf4688F0603112E5695838",
        "reff": "0xE1e74C832B46C609682C096F9e6A0615AC43Bb66"
    },
    {
        "tokenId": 894,
        "owner": "0x87a5b0638031DccbD93D4ae11773e6c7776175a7",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 895,
        "owner": "0x87a5b0638031DccbD93D4ae11773e6c7776175a7",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 896,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 897,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 898,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 899,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 900,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 901,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 902,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 903,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 904,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 905,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 906,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 907,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 908,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 909,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 910,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 911,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 912,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 913,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 914,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 915,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 916,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 917,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 918,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 919,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 920,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 921,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 922,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 923,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 924,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 925,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 926,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 927,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0xD95f6082506154f58B6034DC9ACA14C0369494b6"
    },
    {
        "tokenId": 928,
        "owner": "0xD95f6082506154f58B6034DC9ACA14C0369494b6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 929,
        "owner": "0xD95f6082506154f58B6034DC9ACA14C0369494b6",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 930,
        "owner": "0xE32A4D08f94f10CE35171c77Cc08159669BC95B9",
        "reff": "0x89670214413cE3958D4de7aAEBce46bDc9583faa"
    },
    {
        "tokenId": 931,
        "owner": "0x89670214413cE3958D4de7aAEBce46bDc9583faa",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 932,
        "owner": "0x89670214413cE3958D4de7aAEBce46bDc9583faa",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 933,
        "owner": "0xAe7D130e92fee0972BD323CDE655Cf4A26983CeC",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 934,
        "owner": "0x197F17E4Fa308a175ED29DA912deA364DC9a5fa3",
        "reff": "0xA83f9C5642c8eD43A1d44B7D40874b1A265EE0E9"
    },
    {
        "tokenId": 935,
        "owner": "0x52Cba81b6b678a609615384f98ab5942e28d57b1",
        "reff": "0xA83f9C5642c8eD43A1d44B7D40874b1A265EE0E9"
    },
    {
        "tokenId": 936,
        "owner": "0x52Cba81b6b678a609615384f98ab5942e28d57b1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 937,
        "owner": "0x52Cba81b6b678a609615384f98ab5942e28d57b1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 938,
        "owner": "0x52Cba81b6b678a609615384f98ab5942e28d57b1",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 939,
        "owner": "0x52Cba81b6b678a609615384f98ab5942e28d57b1",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 940,
        "owner": "0x0C84F6A3E261711D836Abb0450EbbBa7628d5eaf",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 941,
        "owner": "0x0C84F6A3E261711D836Abb0450EbbBa7628d5eaf",
        "reff": "0xfE40bcE83d5C6e46872408DE188fFC308E3204D7"
    },
    {
        "tokenId": 942,
        "owner": "0x58792704B4e13492B937d5188fCd1CF1c5064484",
        "reff": "0x21F04028A270303e1773Caf7F587B42b4a0e6555"
    },
    {
        "tokenId": 943,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 944,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 945,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 946,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 947,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 948,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 949,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 950,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 951,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 952,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 953,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 954,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 955,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 956,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 957,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 958,
        "owner": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03",
        "reff": "0x6D37857c4bb3c04C750F9Fe26fE1E8D13A725B03"
    },
    {
        "tokenId": 959,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 960,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 961,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 962,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 963,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 964,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 965,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 966,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 967,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 968,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 969,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 970,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 971,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 972,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 973,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 974,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 975,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 976,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 977,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 978,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 979,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 980,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 981,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 982,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 983,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 984,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 985,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 986,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 987,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 988,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 989,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 990,
        "owner": "0x63436BC5d54112A0534003D8C39458f5db68D6A0",
        "reff": "0xE1e74C832B46C609682C096F9e6A0615AC43Bb66"
    },
    {
        "tokenId": 991,
        "owner": "0x6e446eC6d17d5Cb1014E1A828D57AB8eeb6fE000",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 992,
        "owner": "0x433556c39a3aB56E1E1C05dD39D0F44cEF260228",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 993,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 994,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 995,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 996,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 997,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 998,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 999,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1000,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1001,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x21F04028A270303e1773Caf7F587B42b4a0e6555"
    },
    {
        "tokenId": 1002,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1003,
        "owner": "0xF0FD4bD5A5178eb2A9f443acEd86AaEf54D2696A",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1004,
        "owner": "0xF0FD4bD5A5178eb2A9f443acEd86AaEf54D2696A",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1005,
        "owner": "0xF0FD4bD5A5178eb2A9f443acEd86AaEf54D2696A",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1006,
        "owner": "0xF0FD4bD5A5178eb2A9f443acEd86AaEf54D2696A",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1007,
        "owner": "0xF0FD4bD5A5178eb2A9f443acEd86AaEf54D2696A",
        "reff": "0xE1e74C832B46C609682C096F9e6A0615AC43Bb66"
    },
    {
        "tokenId": 1008,
        "owner": "0xF7C319CE2BDD75792bEDFdf5455722c0d466c26f",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1009,
        "owner": "0x2A97D73E58bf9eC36369a70EDB891a2940582EAb",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1010,
        "owner": "0x2A97D73E58bf9eC36369a70EDB891a2940582EAb",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1011,
        "owner": "0x2A97D73E58bf9eC36369a70EDB891a2940582EAb",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1012,
        "owner": "0x2A97D73E58bf9eC36369a70EDB891a2940582EAb",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1013,
        "owner": "0x2A97D73E58bf9eC36369a70EDB891a2940582EAb",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1014,
        "owner": "0x2A97D73E58bf9eC36369a70EDB891a2940582EAb",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1015,
        "owner": "0x2A97D73E58bf9eC36369a70EDB891a2940582EAb",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1016,
        "owner": "0x2A97D73E58bf9eC36369a70EDB891a2940582EAb",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1017,
        "owner": "0x2A97D73E58bf9eC36369a70EDB891a2940582EAb",
        "reff": "0x97894eD238e61C72eC7DBdC001eEf9A0001DaCc3"
    },
    {
        "tokenId": 1018,
        "owner": "0x97894eD238e61C72eC7DBdC001eEf9A0001DaCc3",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1019,
        "owner": "0x97894eD238e61C72eC7DBdC001eEf9A0001DaCc3",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 1020,
        "owner": "0xb24D2f58bD833699D7b6949dBCcFcc1175A038b1",
        "reff": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB"
    },
    {
        "tokenId": 1021,
        "owner": "0x4e2024E5F1542fE4A9Ee2DE7d53Ab4Ab0eb0FaCe",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1022,
        "owner": "0x8940d4729F42dADECCF6A3d4423296e5da632bd1",
        "reff": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB"
    },
    {
        "tokenId": 1023,
        "owner": "0x0CA12D3847FD39EeB91B1Cf0709250C803150429",
        "reff": "0xD032750a8Cc53E0dEBBC8080A03d85FC71ed90aa"
    },
    {
        "tokenId": 1024,
        "owner": "0xE6b61c4987E538bF5e85bcaBBbc3C8b49B73DE65",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1025,
        "owner": "0xE6b61c4987E538bF5e85bcaBBbc3C8b49B73DE65",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1026,
        "owner": "0xE6b61c4987E538bF5e85bcaBBbc3C8b49B73DE65",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1027,
        "owner": "0xE6b61c4987E538bF5e85bcaBBbc3C8b49B73DE65",
        "reff": "0x8D0372867bfb4f4BE836e17bE2606BcBD6E6FB31"
    },
    {
        "tokenId": 1028,
        "owner": "0xeBb35696c1ddb2058A937DE5709a54209d4AeC60",
        "reff": "0xD032750a8Cc53E0dEBBC8080A03d85FC71ed90aa"
    },
    {
        "tokenId": 1029,
        "owner": "0x17d91CbDf6Bc23C92eBDEeB3970FDb24dF87E771",
        "reff": "0x3c8d8F1AdbDE4F7711c9af33A803f6506dBD6EDB"
    },
    {
        "tokenId": 1030,
        "owner": "0x4f646A93Ba2eC11cF61620461af5bD1E1A9d829A",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1031,
        "owner": "0x4f646A93Ba2eC11cF61620461af5bD1E1A9d829A",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1032,
        "owner": "0x4f646A93Ba2eC11cF61620461af5bD1E1A9d829A",
        "reff": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8"
    },
    {
        "tokenId": 1033,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1034,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB"
    },
    {
        "tokenId": 1035,
        "owner": "0xDDE0Ff126Eb69D6998fdC658B6BC7F7374c2953B",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1036,
        "owner": "0xDDE0Ff126Eb69D6998fdC658B6BC7F7374c2953B",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1037,
        "owner": "0xDDE0Ff126Eb69D6998fdC658B6BC7F7374c2953B",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1038,
        "owner": "0xDDE0Ff126Eb69D6998fdC658B6BC7F7374c2953B",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1039,
        "owner": "0xDDE0Ff126Eb69D6998fdC658B6BC7F7374c2953B",
        "reff": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB"
    },
    {
        "tokenId": 1040,
        "owner": "0xdfA4Ad130eC266635be225b60bF7fd378b7A40aC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1041,
        "owner": "0xdfA4Ad130eC266635be225b60bF7fd378b7A40aC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1042,
        "owner": "0xdfA4Ad130eC266635be225b60bF7fd378b7A40aC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1043,
        "owner": "0xdfA4Ad130eC266635be225b60bF7fd378b7A40aC",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1044,
        "owner": "0xdfA4Ad130eC266635be225b60bF7fd378b7A40aC",
        "reff": "0x460422e68f8C53bc712ec3A5409B2C5EEdE28671"
    },
    {
        "tokenId": 1045,
        "owner": "0xA42eD14Be1179b1740433782fD418f40a6a4c068",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1046,
        "owner": "0xA42eD14Be1179b1740433782fD418f40a6a4c068",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1047,
        "owner": "0xA42eD14Be1179b1740433782fD418f40a6a4c068",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1048,
        "owner": "0xA42eD14Be1179b1740433782fD418f40a6a4c068",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1049,
        "owner": "0xA42eD14Be1179b1740433782fD418f40a6a4c068",
        "reff": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB"
    },
    {
        "tokenId": 1050,
        "owner": "0xaC8F87AaD0d122454AE4AadB586e486eDe1De295",
        "reff": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB"
    },
    {
        "tokenId": 1051,
        "owner": "0x074055128cECEa0f8346D92986A1b588317eC97D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1052,
        "owner": "0x074055128cECEa0f8346D92986A1b588317eC97D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1053,
        "owner": "0x074055128cECEa0f8346D92986A1b588317eC97D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1054,
        "owner": "0x074055128cECEa0f8346D92986A1b588317eC97D",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1055,
        "owner": "0x074055128cECEa0f8346D92986A1b588317eC97D",
        "reff": "0xaC8F87AaD0d122454AE4AadB586e486eDe1De295"
    },
    {
        "tokenId": 1056,
        "owner": "0xc4CC438282AA71568cd331f467e8dE94417642d9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1057,
        "owner": "0xc4CC438282AA71568cd331f467e8dE94417642d9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1058,
        "owner": "0xc4CC438282AA71568cd331f467e8dE94417642d9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1059,
        "owner": "0xc4CC438282AA71568cd331f467e8dE94417642d9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1060,
        "owner": "0xc4CC438282AA71568cd331f467e8dE94417642d9",
        "reff": "0x4e2024E5F1542fE4A9Ee2DE7d53Ab4Ab0eb0FaCe"
    },
    {
        "tokenId": 1061,
        "owner": "0xD7fE795DE1d4E81aE511B11022c40fE0c2677917",
        "reff": "0x4e2024E5F1542fE4A9Ee2DE7d53Ab4Ab0eb0FaCe"
    },
    {
        "tokenId": 1062,
        "owner": "0x532878F750Db65Aba73879b45BEb30eD986ae1e3",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1063,
        "owner": "0x532878F750Db65Aba73879b45BEb30eD986ae1e3",
        "reff": "0x4e2024E5F1542fE4A9Ee2DE7d53Ab4Ab0eb0FaCe"
    },
    {
        "tokenId": 1064,
        "owner": "0xcf2947EF3C683922F254E0B7a5815379322CF6C8",
        "reff": "0x3c8d8F1AdbDE4F7711c9af33A803f6506dBD6EDB"
    },
    {
        "tokenId": 1065,
        "owner": "0x95DD6dF17EB7B4515491Ad0815cf652eb1195241",
        "reff": "0x2e1dC30f1160bD9C6040DCDdD720f130ADb4A5d4"
    },
    {
        "tokenId": 1066,
        "owner": "0x3dc7090741a49EF47d64D13c7E5DAD0960408B20",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 1067,
        "owner": "0x0C84F6A3E261711D836Abb0450EbbBa7628d5eaf",
        "reff": "0xaC8F87AaD0d122454AE4AadB586e486eDe1De295"
    },
    {
        "tokenId": 1068,
        "owner": "0xaC8F87AaD0d122454AE4AadB586e486eDe1De295",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1069,
        "owner": "0xaC8F87AaD0d122454AE4AadB586e486eDe1De295",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1070,
        "owner": "0xaC8F87AaD0d122454AE4AadB586e486eDe1De295",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1071,
        "owner": "0xaC8F87AaD0d122454AE4AadB586e486eDe1De295",
        "reff": "0x95DD6dF17EB7B4515491Ad0815cf652eb1195241"
    },
    {
        "tokenId": 1072,
        "owner": "0xddA44977D99F39A85948f22B7BAE140402d4f7AA",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1073,
        "owner": "0xddA44977D99F39A85948f22B7BAE140402d4f7AA",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1074,
        "owner": "0xddA44977D99F39A85948f22B7BAE140402d4f7AA",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1075,
        "owner": "0xddA44977D99F39A85948f22B7BAE140402d4f7AA",
        "reff": "0x95DD6dF17EB7B4515491Ad0815cf652eb1195241"
    },
    {
        "tokenId": 1076,
        "owner": "0x5DC0C79D41D60b4165470Cf487b0F6ef55b81e36",
        "reff": "0x4e2024E5F1542fE4A9Ee2DE7d53Ab4Ab0eb0FaCe"
    },
    {
        "tokenId": 1077,
        "owner": "0xaE0Cc221874fc72d56d61bEF3972CA149BeC29d3",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1078,
        "owner": "0xaE0Cc221874fc72d56d61bEF3972CA149BeC29d3",
        "reff": "0x4e2024E5F1542fE4A9Ee2DE7d53Ab4Ab0eb0FaCe"
    },
    {
        "tokenId": 1079,
        "owner": "0x6098F91E25BF6bbB8CA4a3741A9C9ec8e6a41DDF",
        "reff": "0x4e2024E5F1542fE4A9Ee2DE7d53Ab4Ab0eb0FaCe"
    },
    {
        "tokenId": 1080,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x95DD6dF17EB7B4515491Ad0815cf652eb1195241"
    },
    {
        "tokenId": 1081,
        "owner": "0xa8C16faaaBeFBF698dDC0405923ce032b134609e",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1082,
        "owner": "0xa8C16faaaBeFBF698dDC0405923ce032b134609e",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1083,
        "owner": "0x8d0f557EfF0175A055812B40E95B96C4Beeb8649",
        "reff": "0x4e2024E5F1542fE4A9Ee2DE7d53Ab4Ab0eb0FaCe"
    },
    {
        "tokenId": 1084,
        "owner": "0x3A9e914Cef893d9eCe79D054C2e2E723Cd2e269a",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1085,
        "owner": "0x3A9e914Cef893d9eCe79D054C2e2E723Cd2e269a",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1086,
        "owner": "0x3A9e914Cef893d9eCe79D054C2e2E723Cd2e269a",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1087,
        "owner": "0x3A9e914Cef893d9eCe79D054C2e2E723Cd2e269a",
        "reff": "0x6098F91E25BF6bbB8CA4a3741A9C9ec8e6a41DDF"
    },
    {
        "tokenId": 1088,
        "owner": "0x0273fc081FA2705B9329E78b819a52cb51E58FC2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1089,
        "owner": "0x0273fc081FA2705B9329E78b819a52cb51E58FC2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1090,
        "owner": "0x0273fc081FA2705B9329E78b819a52cb51E58FC2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1091,
        "owner": "0x0273fc081FA2705B9329E78b819a52cb51E58FC2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1092,
        "owner": "0x0273fc081FA2705B9329E78b819a52cb51E58FC2",
        "reff": "0x427cFFb4665025beACf4235C561eD012f3903aC9"
    },
    {
        "tokenId": 1093,
        "owner": "0x57672891C72d43293467cAEB29d44566a029A9BC",
        "reff": "0x57672891C72d43293467cAEB29d44566a029A9BC"
    },
    {
        "tokenId": 1094,
        "owner": "0x5015b296c20b80B93D2c7a6F584a324E92309d06",
        "reff": "0x5015b296c20b80B93D2c7a6F584a324E92309d06"
    },
    {
        "tokenId": 1095,
        "owner": "0x2F5Cf42362D8810Cd794deF73E44dFC18ca4b85e",
        "reff": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3"
    },
    {
        "tokenId": 1096,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1097,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1098,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1099,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1100,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1101,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1102,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1103,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1104,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1105,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1106,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1107,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1108,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1109,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1110,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1111,
        "owner": "0x62839bE84341A5f76749BE7600B4fEbe59EA96D2",
        "reff": "0x460422e68f8C53bc712ec3A5409B2C5EEdE28671"
    },
    {
        "tokenId": 1112,
        "owner": "0x0Ab32b7380187BC55391C65bb1f803e464A06c3a",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1113,
        "owner": "0x0Ab32b7380187BC55391C65bb1f803e464A06c3a",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1114,
        "owner": "0x0Ab32b7380187BC55391C65bb1f803e464A06c3a",
        "reff": "0xA83f9C5642c8eD43A1d44B7D40874b1A265EE0E9"
    },
    {
        "tokenId": 1115,
        "owner": "0x179bA16A9Caa638a36E37CdC765D4543D94746AD",
        "reff": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3"
    },
    {
        "tokenId": 1116,
        "owner": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3",
        "reff": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3"
    },
    {
        "tokenId": 1117,
        "owner": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 1118,
        "owner": "0xEc6E2E71bE2167561276045D906201B07CBF88fC",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1119,
        "owner": "0x2220D34fD3a7CC393FEfc7DF52E64a3F8f546032",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1120,
        "owner": "0x2220D34fD3a7CC393FEfc7DF52E64a3F8f546032",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1121,
        "owner": "0x2220D34fD3a7CC393FEfc7DF52E64a3F8f546032",
        "reff": "0x3dc7090741a49EF47d64D13c7E5DAD0960408B20"
    },
    {
        "tokenId": 1122,
        "owner": "0x001bcBABE13532dA342575A24F5e1dDC014884f0",
        "reff": "0x179bA16A9Caa638a36E37CdC765D4543D94746AD"
    },
    {
        "tokenId": 1123,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1124,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x95DD6dF17EB7B4515491Ad0815cf652eb1195241"
    },
    {
        "tokenId": 1125,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1126,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1127,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1128,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x95DD6dF17EB7B4515491Ad0815cf652eb1195241"
    },
    {
        "tokenId": 1129,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1130,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x2F5Cf42362D8810Cd794deF73E44dFC18ca4b85e"
    },
    {
        "tokenId": 1131,
        "owner": "0x20ebB69e757FB10Cfa35fE6821C23880592DbB92",
        "reff": "0xB58eE84ADB3a6D0C0805F5eF4723BA496d054ddc"
    },
    {
        "tokenId": 1132,
        "owner": "0x618254bE1DeF0C2967737c68A116751a510CfEe6",
        "reff": "0x618254bE1DeF0C2967737c68A116751a510CfEe6"
    },
    {
        "tokenId": 1133,
        "owner": "0xfed63E9374F8801F0b2A6e59F21D8de326cBC34A",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1134,
        "owner": "0x4AdaadEbEc3702406c3E8cc40E0b6B4A452Bc5f2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1135,
        "owner": "0x4AdaadEbEc3702406c3E8cc40E0b6B4A452Bc5f2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1136,
        "owner": "0x4AdaadEbEc3702406c3E8cc40E0b6B4A452Bc5f2",
        "reff": "0xfed63E9374F8801F0b2A6e59F21D8de326cBC34A"
    },
    {
        "tokenId": 1137,
        "owner": "0x43d81930F82c57DE168786a4aA3079f3101E5FcD",
        "reff": "0x20ebB69e757FB10Cfa35fE6821C23880592DbB92"
    },
    {
        "tokenId": 1138,
        "owner": "0x20ebB69e757FB10Cfa35fE6821C23880592DbB92",
        "reff": "0xdE48839D95BfCd53e07a72f6d32F23225447AC0a"
    },
    {
        "tokenId": 1139,
        "owner": "0x33eB5e58dBF9bF2A082C525B369aB7c2Ec320558",
        "reff": "0x20ebB69e757FB10Cfa35fE6821C23880592DbB92"
    },
    {
        "tokenId": 1140,
        "owner": "0x20ebB69e757FB10Cfa35fE6821C23880592DbB92",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1141,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1142,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1143,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1144,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1145,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1146,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1147,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1148,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1149,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1150,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1151,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1152,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1153,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1154,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1155,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1156,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1157,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1158,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1159,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1160,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1161,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x460422e68f8C53bc712ec3A5409B2C5EEdE28671"
    },
    {
        "tokenId": 1162,
        "owner": "0x44433c3F0C662c97BB861c947Bbf3EdC56b65694",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1163,
        "owner": "0x44433c3F0C662c97BB861c947Bbf3EdC56b65694",
        "reff": "0x460422e68f8C53bc712ec3A5409B2C5EEdE28671"
    },
    {
        "tokenId": 1164,
        "owner": "0x44433c3F0C662c97BB861c947Bbf3EdC56b65694",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1165,
        "owner": "0x44433c3F0C662c97BB861c947Bbf3EdC56b65694",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1166,
        "owner": "0x723a18a97a370338f0a73B68AeE334A2A02C0402",
        "reff": "0x20ebB69e757FB10Cfa35fE6821C23880592DbB92"
    },
    {
        "tokenId": 1167,
        "owner": "0xE426EBc032E1bC407a2F2EE57dBaDb1d8FB05b5d",
        "reff": "0xdeb545033c408BF0AE767d22098762fa97DC1F61"
    },
    {
        "tokenId": 1168,
        "owner": "0x83B83F5F8143CfeC675e47919b091337286cDE5B",
        "reff": "0xeBb35696c1ddb2058A937DE5709a54209d4AeC60"
    },
    {
        "tokenId": 1169,
        "owner": "0x15a4E11c060D30D9Ba2f69b47DAcB16e4a529048",
        "reff": "0xE426EBc032E1bC407a2F2EE57dBaDb1d8FB05b5d"
    },
    {
        "tokenId": 1170,
        "owner": "0xA3C29aDF63ED3241483B4ca522F8DA1CfF28be0c",
        "reff": "0x95DD6dF17EB7B4515491Ad0815cf652eb1195241"
    },
    {
        "tokenId": 1171,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1172,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1173,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1174,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1175,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1176,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1177,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1178,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1179,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1180,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1181,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1182,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1183,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1184,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1185,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1186,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0x21F04028A270303e1773Caf7F587B42b4a0e6555"
    },
    {
        "tokenId": 1187,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1188,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1189,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1190,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1191,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1192,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1193,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1194,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1195,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1196,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1197,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1198,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1199,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1200,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1201,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1202,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1203,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1204,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1205,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1206,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1207,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1208,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1209,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1210,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1211,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1212,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1213,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1214,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1215,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1216,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1217,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1218,
        "owner": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233",
        "reff": "0x95DD6dF17EB7B4515491Ad0815cf652eb1195241"
    },
    {
        "tokenId": 1219,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1220,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1221,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1222,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1223,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1224,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1225,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1226,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1227,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1228,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1229,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1230,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1231,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1232,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1233,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1234,
        "owner": "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1235,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1236,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1237,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1238,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1239,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1240,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1241,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1242,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1243,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1244,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1245,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1246,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1247,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1248,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1249,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1250,
        "owner": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E",
        "reff": "0xA83f9C5642c8eD43A1d44B7D40874b1A265EE0E9"
    },
    {
        "tokenId": 1251,
        "owner": "0x75882fc1D1EAC962675Da553A46B4D8250277E85",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1252,
        "owner": "0x75882fc1D1EAC962675Da553A46B4D8250277E85",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1253,
        "owner": "0x75882fc1D1EAC962675Da553A46B4D8250277E85",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1254,
        "owner": "0x75882fc1D1EAC962675Da553A46B4D8250277E85",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1255,
        "owner": "0x75882fc1D1EAC962675Da553A46B4D8250277E85",
        "reff": "0xA83f9C5642c8eD43A1d44B7D40874b1A265EE0E9"
    },
    {
        "tokenId": 1256,
        "owner": "0xA83f9C5642c8eD43A1d44B7D40874b1A265EE0E9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1257,
        "owner": "0xA83f9C5642c8eD43A1d44B7D40874b1A265EE0E9",
        "reff": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3"
    },
    {
        "tokenId": 1258,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1259,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1260,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1261,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1262,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1263,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1264,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1265,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1266,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1267,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1268,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1269,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1270,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1271,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1272,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1273,
        "owner": "0xe4Fe212094cd84Aa635058a11EFe764ae85e7641",
        "reff": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3"
    },
    {
        "tokenId": 1274,
        "owner": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3",
        "reff": "0xaC8F87AaD0d122454AE4AadB586e486eDe1De295"
    },
    {
        "tokenId": 1275,
        "owner": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003",
        "reff": "0x6098F91E25BF6bbB8CA4a3741A9C9ec8e6a41DDF"
    },
    {
        "tokenId": 1276,
        "owner": "0x6098F91E25BF6bbB8CA4a3741A9C9ec8e6a41DDF",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1277,
        "owner": "0x6098F91E25BF6bbB8CA4a3741A9C9ec8e6a41DDF",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1278,
        "owner": "0x6098F91E25BF6bbB8CA4a3741A9C9ec8e6a41DDF",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1279,
        "owner": "0x6098F91E25BF6bbB8CA4a3741A9C9ec8e6a41DDF",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1280,
        "owner": "0x6098F91E25BF6bbB8CA4a3741A9C9ec8e6a41DDF",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1281,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1282,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1283,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1284,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1285,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1286,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1287,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1288,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1289,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1290,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1291,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1292,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1293,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1294,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1295,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1296,
        "owner": "0x3B01F7B817E633BAE41a488156dc66A686c7dfdB",
        "reff": "0x21F04028A270303e1773Caf7F587B42b4a0e6555"
    },
    {
        "tokenId": 1297,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1298,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1299,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1300,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1301,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1302,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1303,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1304,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1305,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1306,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1307,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1308,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1309,
        "owner": "0x21F04028A270303e1773Caf7F587B42b4a0e6555",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1310,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1311,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1312,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1313,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1314,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1315,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1316,
        "owner": "0x99b044Ab4438997d82C4B5589270665A95a8e917",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1317,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1318,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1319,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1320,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1321,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1322,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1323,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1324,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1325,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1326,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1327,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1328,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1329,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1330,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1331,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1332,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1333,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1334,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1335,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1336,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1337,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1338,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1339,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1340,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1341,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1342,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1343,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1344,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1345,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1346,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1347,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1348,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1349,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1350,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1351,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1352,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1353,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1354,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1355,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1356,
        "owner": "0xA67251B05005DcdA29691014eCe14f296c56fc2c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1357,
        "owner": "0xA67251B05005DcdA29691014eCe14f296c56fc2c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1358,
        "owner": "0xA67251B05005DcdA29691014eCe14f296c56fc2c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1359,
        "owner": "0xA67251B05005DcdA29691014eCe14f296c56fc2c",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1360,
        "owner": "0xA67251B05005DcdA29691014eCe14f296c56fc2c",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1361,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1362,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1363,
        "owner": "0xa8C16faaaBeFBF698dDC0405923ce032b134609e",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1364,
        "owner": "0x04B122A42488aF4C952CE14bade45D529ad418d8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1365,
        "owner": "0x04B122A42488aF4C952CE14bade45D529ad418d8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1366,
        "owner": "0x04B122A42488aF4C952CE14bade45D529ad418d8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1367,
        "owner": "0x04B122A42488aF4C952CE14bade45D529ad418d8",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1368,
        "owner": "0x04B122A42488aF4C952CE14bade45D529ad418d8",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1369,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1370,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1371,
        "owner": "0x75d42F1E0cD9ACef08b1315b7e7CCD982a88fAee",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1372,
        "owner": "0x75d42F1E0cD9ACef08b1315b7e7CCD982a88fAee",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1373,
        "owner": "0x75d42F1E0cD9ACef08b1315b7e7CCD982a88fAee",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1374,
        "owner": "0x7f85F15cd70130aCF401B0DCE0B46ac18385a830",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1375,
        "owner": "0x7f85F15cd70130aCF401B0DCE0B46ac18385a830",
        "reff": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3"
    },
    {
        "tokenId": 1376,
        "owner": "0xfFCc336dda6ED8200F094CD2C8057bB0474569f2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1377,
        "owner": "0xfFCc336dda6ED8200F094CD2C8057bB0474569f2",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1378,
        "owner": "0xfFCc336dda6ED8200F094CD2C8057bB0474569f2",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1379,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1380,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1381,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1382,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1383,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1384,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1385,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1386,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1387,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1388,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1389,
        "owner": "0xd545631531C654E031d7c2Fe3fB8e22C786F3bA9",
        "reff": "0xdE48839D95BfCd53e07a72f6d32F23225447AC0a"
    },
    {
        "tokenId": 1390,
        "owner": "0x33eB5e58dBF9bF2A082C525B369aB7c2Ec320558",
        "reff": "0xc05A2a4D08314C9E0B58788B96a9B8E59F007BFD"
    },
    {
        "tokenId": 1391,
        "owner": "0x8d637e82250E53098531fFA2dE62Ae429a508Ba3",
        "reff": "0xc05A2a4D08314C9E0B58788B96a9B8E59F007BFD"
    },
    {
        "tokenId": 1392,
        "owner": "0xD5E33a35a50dc5350465A4abAbFc1503a2DC0379",
        "reff": "0xc05A2a4D08314C9E0B58788B96a9B8E59F007BFD"
    },
    {
        "tokenId": 1393,
        "owner": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C",
        "reff": "0x49C64de99A5EC4647F9DdC249f27F1526DA784F9"
    },
    {
        "tokenId": 1394,
        "owner": "0xD892C90c5f153EC5a81AFA0e34f39375df7c65bb",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1395,
        "owner": "0xD892C90c5f153EC5a81AFA0e34f39375df7c65bb",
        "reff": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3"
    },
    {
        "tokenId": 1396,
        "owner": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3",
        "reff": "0x0000000000000000000000000000000000000000"
    },
    {
        "tokenId": 1397,
        "owner": "0x6552679f5D35Cf214F2eEf52379D8A789d42Dbea",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1398,
        "owner": "0xfbcB47Ca3B68F8FDAb9EaD2D391451E431927820",
        "reff": "0xA3C29aDF63ED3241483B4ca522F8DA1CfF28be0c"
    },
    {
        "tokenId": 1399,
        "owner": "0xF7989104A106182d5835336941F04e4A5558eFc8",
        "reff": "0xA3C29aDF63ED3241483B4ca522F8DA1CfF28be0c"
    },
    {
        "tokenId": 1400,
        "owner": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3",
        "reff": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3"
    },
    {
        "tokenId": 1401,
        "owner": "0xD2Ae22f2Cc13aa28998E766dACdF68fC100893e1",
        "reff": "0x6552679f5D35Cf214F2eEf52379D8A789d42Dbea"
    },
    {
        "tokenId": 1402,
        "owner": "0xe013998326c4b2FE7E604bDCf0c967E4C6d51eF5",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1403,
        "owner": "0xe013998326c4b2FE7E604bDCf0c967E4C6d51eF5",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1404,
        "owner": "0xe013998326c4b2FE7E604bDCf0c967E4C6d51eF5",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1405,
        "owner": "0xe013998326c4b2FE7E604bDCf0c967E4C6d51eF5",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1406,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1407,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1408,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1409,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1410,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1411,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1412,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1413,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1414,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1415,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1416,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1417,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1418,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1419,
        "owner": "0x861C571d90006782a8694b65Bac3a3AF28FE2687",
        "reff": "0xc05A2a4D08314C9E0B58788B96a9B8E59F007BFD"
    },
    {
        "tokenId": 1420,
        "owner": "0xA1db575E054ddb12Ac4f1E8c97430586Da3220C5",
        "reff": "0x861C571d90006782a8694b65Bac3a3AF28FE2687"
    },
    {
        "tokenId": 1421,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x861C571d90006782a8694b65Bac3a3AF28FE2687"
    },
    {
        "tokenId": 1422,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1423,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1424,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1425,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1426,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1427,
        "owner": "0x9018879682fBdF32796103101b954b14BA15CA66",
        "reff": "0x861C571d90006782a8694b65Bac3a3AF28FE2687"
    },
    {
        "tokenId": 1428,
        "owner": "0x9018879682fBdF32796103101b954b14BA15CA66",
        "reff": "0x9018879682fBdF32796103101b954b14BA15CA66"
    },
    {
        "tokenId": 1429,
        "owner": "0x43e20DfDf5047695Fe166E6a9D4F0b296F975365",
        "reff": "0x861C571d90006782a8694b65Bac3a3AF28FE2687"
    },
    {
        "tokenId": 1430,
        "owner": "0xfF3c8f18B58eDddD1e64d6891C03870865CD35C3",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1431,
        "owner": "0xfF3c8f18B58eDddD1e64d6891C03870865CD35C3",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1432,
        "owner": "0xfF3c8f18B58eDddD1e64d6891C03870865CD35C3",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1433,
        "owner": "0xfF3c8f18B58eDddD1e64d6891C03870865CD35C3",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1434,
        "owner": "0xfF3c8f18B58eDddD1e64d6891C03870865CD35C3",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1435,
        "owner": "0xfF3c8f18B58eDddD1e64d6891C03870865CD35C3",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1436,
        "owner": "0xfF3c8f18B58eDddD1e64d6891C03870865CD35C3",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1437,
        "owner": "0xfF3c8f18B58eDddD1e64d6891C03870865CD35C3",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1438,
        "owner": "0xfF3c8f18B58eDddD1e64d6891C03870865CD35C3",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1439,
        "owner": "0xfF3c8f18B58eDddD1e64d6891C03870865CD35C3",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1440,
        "owner": "0xfF3c8f18B58eDddD1e64d6891C03870865CD35C3",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1441,
        "owner": "0xfF3c8f18B58eDddD1e64d6891C03870865CD35C3",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1442,
        "owner": "0x517066fb221819C3D7acD3677458B7FCCf6BFfd9",
        "reff": "0xF7989104A106182d5835336941F04e4A5558eFc8"
    },
    {
        "tokenId": 1443,
        "owner": "0x9018879682fBdF32796103101b954b14BA15CA66",
        "reff": "0x9018879682fBdF32796103101b954b14BA15CA66"
    },
    {
        "tokenId": 1444,
        "owner": "0xf2471678d65EcE4E824e5C30c3d8426945498d58",
        "reff": "0x9018879682fBdF32796103101b954b14BA15CA66"
    },
    {
        "tokenId": 1445,
        "owner": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7",
        "reff": "0x861C571d90006782a8694b65Bac3a3AF28FE2687"
    },
    {
        "tokenId": 1446,
        "owner": "0xAC2B22Ba325a27d71a994e8B3205243Af23f56e6",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 1447,
        "owner": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51",
        "reff": "0x9018879682fBdF32796103101b954b14BA15CA66"
    },
    {
        "tokenId": 1448,
        "owner": "0xB9a675Efd7a59367731cA0f264146eab3E66C79e",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1449,
        "owner": "0xB9a675Efd7a59367731cA0f264146eab3E66C79e",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1450,
        "owner": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1451,
        "owner": "0xEF93Ae506c7f2745a8C5bce2FBf4DCdE533e98D5",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1452,
        "owner": "0x4FDbbD20E7EeB7812aaaB7A3B0362c427d714360",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1453,
        "owner": "0x9018879682fBdF32796103101b954b14BA15CA66",
        "reff": "0x9018879682fBdF32796103101b954b14BA15CA66"
    },
    {
        "tokenId": 1454,
        "owner": "0xbafD99BD4a4d2a5A62F2Db250C4EB4de674b0cca",
        "reff": "0xbafD99BD4a4d2a5A62F2Db250C4EB4de674b0cca"
    },
    {
        "tokenId": 1455,
        "owner": "0xF35C96199Bc8D569150455e88b33F4c75eB38C85",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1456,
        "owner": "0xf51200ba6Efc8f5D695Cc7FE0798671b1F586CE3",
        "reff": "0xF7989104A106182d5835336941F04e4A5558eFc8"
    },
    {
        "tokenId": 1457,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1458,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1459,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1460,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1461,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1462,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1463,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1464,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1465,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1466,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1467,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1468,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8"
    },
    {
        "tokenId": 1469,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1470,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1471,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1472,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1473,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1474,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1475,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1476,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1477,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1478,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1479,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1480,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1481,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1482,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1483,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1484,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1485,
        "owner": "0x3821F2560CC1363376c92A78458cA51F13c4149c",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1486,
        "owner": "0x60a44627C78b5764721eeB10c9d1164aC65ECEE4",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1487,
        "owner": "0x60a44627C78b5764721eeB10c9d1164aC65ECEE4",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1488,
        "owner": "0x60a44627C78b5764721eeB10c9d1164aC65ECEE4",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1489,
        "owner": "0x60a44627C78b5764721eeB10c9d1164aC65ECEE4",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1490,
        "owner": "0x60a44627C78b5764721eeB10c9d1164aC65ECEE4",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1491,
        "owner": "0x60a44627C78b5764721eeB10c9d1164aC65ECEE4",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1492,
        "owner": "0x60a44627C78b5764721eeB10c9d1164aC65ECEE4",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1493,
        "owner": "0x60a44627C78b5764721eeB10c9d1164aC65ECEE4",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1494,
        "owner": "0xb643E36973A684dCBc38788c1099B0a98BFA8508",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1495,
        "owner": "0xb643E36973A684dCBc38788c1099B0a98BFA8508",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1496,
        "owner": "0xb643E36973A684dCBc38788c1099B0a98BFA8508",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1497,
        "owner": "0xb643E36973A684dCBc38788c1099B0a98BFA8508",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1498,
        "owner": "0x385C7477A3bfaA0b566E75c76A6bb94ca2D37Ec4",
        "reff": "0x0B12d4D06A73648E921e82dff75F8C73847A45f6"
    },
    {
        "tokenId": 1499,
        "owner": "0xE0d9fCA54975a90AeEcC7E418DAFFc3baF51FfF4",
        "reff": "0xeBb35696c1ddb2058A937DE5709a54209d4AeC60"
    },
    {
        "tokenId": 1500,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1501,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1502,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1503,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1504,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1505,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1506,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1507,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1508,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1509,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1510,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1511,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1512,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1513,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1514,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1515,
        "owner": "0xDeBA18Ef1CABA845dA1DE1e4c5A9Cd88181168EE",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1516,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1517,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1518,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1519,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1520,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1521,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1522,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1523,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1524,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1525,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1526,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1527,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1528,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1529,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1530,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1531,
        "owner": "0xc1784904Dc8C3895E1a2F032378Def85E4a4d3A9",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1532,
        "owner": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3",
        "reff": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3"
    },
    {
        "tokenId": 1533,
        "owner": "0x218fB03284D710053848154fFe2d50e5e7198055",
        "reff": "0xF35C96199Bc8D569150455e88b33F4c75eB38C85"
    },
    {
        "tokenId": 1534,
        "owner": "0x218fB03284D710053848154fFe2d50e5e7198055",
        "reff": "0xF35C96199Bc8D569150455e88b33F4c75eB38C85"
    },
    {
        "tokenId": 1535,
        "owner": "0x1D5fE30AbFEF5C21a6Cdda4e1FC8577A59d183e6",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1536,
        "owner": "0x3746AfE9710FAcec3075A8Cae84B0FD3f028d901",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1537,
        "owner": "0x71861D1F995e1d506B277032Ef2480DaF6D346c1",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1538,
        "owner": "0x71861D1F995e1d506B277032Ef2480DaF6D346c1",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1539,
        "owner": "0x71861D1F995e1d506B277032Ef2480DaF6D346c1",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1540,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1541,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1542,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1543,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1544,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1545,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1546,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1547,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1548,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1549,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1550,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1551,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1552,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1553,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1554,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1555,
        "owner": "0x515E495CDFD6EAb627Cb07e79560BA056A71FC9a",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1556,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8"
    },
    {
        "tokenId": 1557,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8"
    },
    {
        "tokenId": 1558,
        "owner": "0xf0a669eCcF4262B9f18B4AAE2126917291c90F2C",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1559,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1560,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1561,
        "owner": "0x46f9662D4794BB170294953EA33F98D0ad635A4F",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1562,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1563,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1564,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1565,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1566,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1567,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1568,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1569,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1570,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1571,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1572,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1573,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1574,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1575,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1576,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1577,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1578,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1579,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1580,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1581,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1582,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1583,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1584,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1585,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1586,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1587,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1588,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1589,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1590,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1591,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1592,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1593,
        "owner": "0xA43F0D7B8B1A2C7eF5352604C68e2cbF4391593F",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1594,
        "owner": "0xD2faaCBFFAFf262495A55815F8fE79B747c8C335",
        "reff": "0x2751Dd3716F13Be8c8Df134139b925112D915a65"
    },
    {
        "tokenId": 1595,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1596,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1597,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1598,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1599,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1600,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1601,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1602,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1603,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1604,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1605,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1606,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1607,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1608,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1609,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1610,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1611,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1612,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1613,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1614,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1615,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1616,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1617,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1618,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1619,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1620,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1621,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1622,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1623,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1624,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1625,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1626,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1627,
        "owner": "0xC0f3b93FEab94e98cA71118f0c0dAfE774671ffe",
        "reff": "0xB53d0A3F3144c15419467b678dBb74f61BCf8f4E"
    },
    {
        "tokenId": 1628,
        "owner": "0x71C6e964042D16eE649473e7Cc3e7FcB1913041A",
        "reff": "0xa89119C4D16C4A1cb9e202fDF354C1e708EE5f23"
    },
    {
        "tokenId": 1629,
        "owner": "0xC5FB6dF2CdAF45059D83fa91D873b6F914c65458",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1630,
        "owner": "0xC5FB6dF2CdAF45059D83fa91D873b6F914c65458",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1631,
        "owner": "0xD40146F9cd379b4A258491d3D46E3877f5271d47",
        "reff": "0xa89119C4D16C4A1cb9e202fDF354C1e708EE5f23"
    },
    {
        "tokenId": 1632,
        "owner": "0x8C0D621667c2447F48f0eFeC2E8Aa0F9BdfE07F4",
        "reff": "0x15a4E11c060D30D9Ba2f69b47DAcB16e4a529048"
    },
    {
        "tokenId": 1633,
        "owner": "0x721EaD37dB1E3AFc738D7E96f6e16815261dC822",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1634,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 1635,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 1636,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 1637,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 1638,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 1639,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 1640,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 1641,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 1642,
        "owner": "0x721EaD37dB1E3AFc738D7E96f6e16815261dC822",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1643,
        "owner": "0xe3C28c2C71F583158e614dED6da023045Ce6Ba73",
        "reff": "0x747c78F46c90dAf860D6C093E669B10A7A52BC0a"
    },
    {
        "tokenId": 1644,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1645,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1646,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1647,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1648,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1649,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1650,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1651,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1652,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1653,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1654,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1655,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1656,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1657,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1658,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1659,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C"
    },
    {
        "tokenId": 1660,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1661,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1662,
        "owner": "0x2100530e2C9EbCeD0bbbfB5707445d31Eacd49eB",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 1663,
        "owner": "0xB8052A15F0e35cCc685EDb2CC3A0E355084eD39d",
        "reff": "0x532878F750Db65Aba73879b45BEb30eD986ae1e3"
    },
    {
        "tokenId": 1664,
        "owner": "0xf50399fB21D52662eaEA457f17C8ACF6e0a0c06B",
        "reff": "0xfbcB47Ca3B68F8FDAb9EaD2D391451E431927820"
    },
    {
        "tokenId": 1665,
        "owner": "0x721EaD37dB1E3AFc738D7E96f6e16815261dC822",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1666,
        "owner": "0x721EaD37dB1E3AFc738D7E96f6e16815261dC822",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1667,
        "owner": "0x721EaD37dB1E3AFc738D7E96f6e16815261dC822",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1668,
        "owner": "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1669,
        "owner": "0x2Ca2141f1578d6f66499349221F0eB3df4839aae",
        "reff": "0xE0d9fCA54975a90AeEcC7E418DAFFc3baF51FfF4"
    },
    {
        "tokenId": 1670,
        "owner": "0x29ee66b515a826946c8EA94EEd2ef3dfb0c114b9",
        "reff": "0xa89119C4D16C4A1cb9e202fDF354C1e708EE5f23"
    },
    {
        "tokenId": 1671,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1672,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1673,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1674,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1675,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1676,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1677,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1678,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1679,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1680,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1681,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1682,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1683,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1684,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1685,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1686,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 1687,
        "owner": "0x1d8914442a1d3f8f10822FAa91069A1e8F03a914",
        "reff": "0x8C0D621667c2447F48f0eFeC2E8Aa0F9BdfE07F4"
    },
    {
        "tokenId": 1688,
        "owner": "0xf650C79265c03E222377849e2e228bBA5f970139",
        "reff": "0x1df7bfAC3aC3D5673FC3a738A8b5e8B5CDa789F3"
    },
    {
        "tokenId": 1689,
        "owner": "0xe8F7Af1be0091Cfa0D7aC4777AfF2F0d4c2518ab",
        "reff": "0x8D0372867bfb4f4BE836e17bE2606BcBD6E6FB31"
    },
    {
        "tokenId": 1690,
        "owner": "0xC2Dc324Fc854b31a26f56f8f44EEEA84bfDC730D",
        "reff": "0x618254bE1DeF0C2967737c68A116751a510CfEe6"
    },
    {
        "tokenId": 1691,
        "owner": "0x313B917D2126a01ff073cb94882cFF2F6592bc98",
        "reff": "0x2Ca2141f1578d6f66499349221F0eB3df4839aae"
    },
    {
        "tokenId": 1692,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 1693,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 1694,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E"
    },
    {
        "tokenId": 1695,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E"
    },
    {
        "tokenId": 1696,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E"
    },
    {
        "tokenId": 1697,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E"
    },
    {
        "tokenId": 1698,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E"
    },
    {
        "tokenId": 1699,
        "owner": "0x07c39e4C961eB92edcbA51d510932Ea3A7543BBd",
        "reff": "0x8C0D621667c2447F48f0eFeC2E8Aa0F9BdfE07F4"
    },
    {
        "tokenId": 1700,
        "owner": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
        "reff": "0x7e9a503c200468f9A3E85C99d15b269B2C79339E"
    },
    {
        "tokenId": 1701,
        "owner": "0xF4E4D51050DEaa936874A1F4E1217E5E14F21138",
        "reff": "0x07c39e4C961eB92edcbA51d510932Ea3A7543BBd"
    },
    {
        "tokenId": 1702,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1703,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1704,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1705,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1706,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1707,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1708,
        "owner": "0x0f354C94e0BDA51d550B47C04C40fD61374c5510",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1709,
        "owner": "0xF015E4e54d9d103CA7D76E05b8B1Fef8F6E2BBBE",
        "reff": "0xf50399fB21D52662eaEA457f17C8ACF6e0a0c06B"
    },
    {
        "tokenId": 1710,
        "owner": "0x24C74D514907dBb8A3FC68dd6ECbda39989058C4",
        "reff": "0xC2Dc324Fc854b31a26f56f8f44EEEA84bfDC730D"
    },
    {
        "tokenId": 1711,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1712,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1713,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1714,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1715,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1716,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1717,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1718,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1719,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1720,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1721,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1722,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1723,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1724,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1725,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1726,
        "owner": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 1727,
        "owner": "0xEB5352021690c10eC3EF82c7203ca9B0a252e173",
        "reff": "0x24C74D514907dBb8A3FC68dd6ECbda39989058C4"
    },
    {
        "tokenId": 1728,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1729,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1730,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1731,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1732,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1733,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1734,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1735,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1736,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1737,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1738,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1739,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1740,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1741,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1742,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1743,
        "owner": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 1744,
        "owner": "0x58d0A365328Fd9db3BEBA8083Cd62b1F6Ee7C559",
        "reff": "0x9748a170531B9cE08c6302C133FF8760816c8EfA"
    },
    {
        "tokenId": 1745,
        "owner": "0x58d0A365328Fd9db3BEBA8083Cd62b1F6Ee7C559",
        "reff": "0x9748a170531B9cE08c6302C133FF8760816c8EfA"
    },
    {
        "tokenId": 1746,
        "owner": "0xe3C28c2C71F583158e614dED6da023045Ce6Ba73",
        "reff": "0x747c78F46c90dAf860D6C093E669B10A7A52BC0a"
    },
    {
        "tokenId": 1747,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8"
    },
    {
        "tokenId": 1748,
        "owner": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8",
        "reff": "0x19Eea159DE33FCc13A3b71CC994b6e3Ec4BF93B8"
    },
    {
        "tokenId": 1749,
        "owner": "0xEB43DA9E28e48Fb0ABfBCA6C50458db1A15FC374",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1750,
        "owner": "0xEB43DA9E28e48Fb0ABfBCA6C50458db1A15FC374",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1751,
        "owner": "0xF3f5776858A9135C789D7032C0C2216a64541f59",
        "reff": "0xa967da3b3CefeD637f3a921A50D8D01fA06D1205"
    },
    {
        "tokenId": 1752,
        "owner": "0xf0a669eCcF4262B9f18B4AAE2126917291c90F2C",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1753,
        "owner": "0x1c5f0fe1F219dB6d3598E948d2c74644d7c2E247",
        "reff": "0xf51200ba6Efc8f5D695Cc7FE0798671b1F586CE3"
    },
    {
        "tokenId": 1754,
        "owner": "0xA43AC7477Ab29bc7442d89b69842b6A7da72C685",
        "reff": "0xacDa8b6120e221a1C4dd288A88Ed9E6c88247649"
    },
    {
        "tokenId": 1755,
        "owner": "0xA43AC7477Ab29bc7442d89b69842b6A7da72C685",
        "reff": "0xacDa8b6120e221a1C4dd288A88Ed9E6c88247649"
    },
    {
        "tokenId": 1756,
        "owner": "0xC6d0d9d899e10dA17218EA671939219094Fcf220",
        "reff": "0xacDa8b6120e221a1C4dd288A88Ed9E6c88247649"
    },
    {
        "tokenId": 1757,
        "owner": "0xacDa8b6120e221a1C4dd288A88Ed9E6c88247649",
        "reff": "0xacDa8b6120e221a1C4dd288A88Ed9E6c88247649"
    },
    {
        "tokenId": 1758,
        "owner": "0x2F5Cf42362D8810Cd794deF73E44dFC18ca4b85e",
        "reff": "0x2F5Cf42362D8810Cd794deF73E44dFC18ca4b85e"
    },
    {
        "tokenId": 1759,
        "owner": "0x1e31f3c39fAA61FFc8a7C94110452545Fc28Dd3d",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 1760,
        "owner": "0x42a9Ed1aba81dec5889D3C1B2aF11a4b1FA93F8c",
        "reff": "0x8A3E707a035C6FCEa2c420ee8f2053A6fb3905f2"
    },
    {
        "tokenId": 1761,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1762,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1763,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1764,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1765,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1766,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1767,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1768,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1769,
        "owner": "0xeE6c60ff8c931f467e1BA9c1F715B98D4B8d7A91",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1770,
        "owner": "0xeE6c60ff8c931f467e1BA9c1F715B98D4B8d7A91",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1771,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1772,
        "owner": "0xcf2947EF3C683922F254E0B7a5815379322CF6C8",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1773,
        "owner": "0xcf2947EF3C683922F254E0B7a5815379322CF6C8",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1774,
        "owner": "0xcf2947EF3C683922F254E0B7a5815379322CF6C8",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1775,
        "owner": "0xcf2947EF3C683922F254E0B7a5815379322CF6C8",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1776,
        "owner": "0xcf2947EF3C683922F254E0B7a5815379322CF6C8",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1777,
        "owner": "0xcf2947EF3C683922F254E0B7a5815379322CF6C8",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1778,
        "owner": "0xcf2947EF3C683922F254E0B7a5815379322CF6C8",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1779,
        "owner": "0x6A762eB5c127876D7533A2eb3F880Ad51ccBc5a3",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1780,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28"
    },
    {
        "tokenId": 1781,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28"
    },
    {
        "tokenId": 1782,
        "owner": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28",
        "reff": "0xFB02F4ED40Ae44A113B5Ae279DC572BC6c7cCA28"
    },
    {
        "tokenId": 1783,
        "owner": "0xf600fE986b54A7d1a2F30b11c39dbcB7F51473dD",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1784,
        "owner": "0xf600fE986b54A7d1a2F30b11c39dbcB7F51473dD",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1785,
        "owner": "0xf600fE986b54A7d1a2F30b11c39dbcB7F51473dD",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1786,
        "owner": "0x43e20DfDf5047695Fe166E6a9D4F0b296F975365",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1787,
        "owner": "0x43e20DfDf5047695Fe166E6a9D4F0b296F975365",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1788,
        "owner": "0x8dfec51e963983151C4945bE7CC6966eD7a83d70",
        "reff": "0x3dc7090741a49EF47d64D13c7E5DAD0960408B20"
    },
    {
        "tokenId": 1789,
        "owner": "0xE376c7a0Cd0138f085c41e622a1548e72DbD2F0b",
        "reff": "0x001bcBABE13532dA342575A24F5e1dDC014884f0"
    },
    {
        "tokenId": 1790,
        "owner": "0xd5aF527958a605C9FcB82F795DCE66DF19aBA755",
        "reff": "0x8dfec51e963983151C4945bE7CC6966eD7a83d70"
    },
    {
        "tokenId": 1791,
        "owner": "0x5D94370F411C67378DaFF827618D6aDf0BC79A89",
        "reff": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4"
    },
    {
        "tokenId": 1792,
        "owner": "0x5D94370F411C67378DaFF827618D6aDf0BC79A89",
        "reff": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4"
    },
    {
        "tokenId": 1793,
        "owner": "0x5D94370F411C67378DaFF827618D6aDf0BC79A89",
        "reff": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4"
    },
    {
        "tokenId": 1794,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1795,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1796,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1797,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1798,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1799,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1800,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1801,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1802,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1803,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1804,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1805,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1806,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1807,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1808,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1809,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1810,
        "owner": "0xabE53816a3b284911C8Dff64f71fbf9Df3BE00CB",
        "reff": "0x8dfec51e963983151C4945bE7CC6966eD7a83d70"
    },
    {
        "tokenId": 1811,
        "owner": "0x1a503C12B879892263bF3c0cbB02fEe081E2e632",
        "reff": "0x1d8914442a1d3f8f10822FAa91069A1e8F03a914"
    },
    {
        "tokenId": 1812,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1813,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1814,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1815,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1816,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1817,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1818,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1819,
        "owner": "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 1820,
        "owner": "0x3C3796E4d024024FA5f6D84b33551b3A4beD842b",
        "reff": "0x1a503C12B879892263bF3c0cbB02fEe081E2e632"
    },
    {
        "tokenId": 1821,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1822,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1823,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1824,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1825,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1826,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1827,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1828,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1829,
        "owner": "0xc91DDEdC8137C467652AB8eBb82f35411D325753",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1830,
        "owner": "0xc91DDEdC8137C467652AB8eBb82f35411D325753",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1831,
        "owner": "0xc91DDEdC8137C467652AB8eBb82f35411D325753",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1832,
        "owner": "0xc91DDEdC8137C467652AB8eBb82f35411D325753",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1833,
        "owner": "0xc91DDEdC8137C467652AB8eBb82f35411D325753",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1834,
        "owner": "0x08EaCF9C79994ba67CB36fF51C2124a4DeDad24c",
        "reff": "0xabE53816a3b284911C8Dff64f71fbf9Df3BE00CB"
    },
    {
        "tokenId": 1835,
        "owner": "0x4c2CEE9b7af5A8e0cEFeDC118f8fe3Ff4Ec0B514",
        "reff": "0x6014EA3b930Cd0cD2b8131A55a2b17f1229110B1"
    },
    {
        "tokenId": 1836,
        "owner": "0x92f751DD72c304dC688de0A8eD5819670039b0FB",
        "reff": "0xd5aF527958a605C9FcB82F795DCE66DF19aBA755"
    },
    {
        "tokenId": 1837,
        "owner": "0x659E3C54935b8FcBF85c3f58B48Fc4434802E90B",
        "reff": "0x0F16185EfdF959640856FE6e037C2c730Eb5Fa09"
    },
    {
        "tokenId": 1838,
        "owner": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156",
        "reff": "0x4c2CEE9b7af5A8e0cEFeDC118f8fe3Ff4Ec0B514"
    },
    {
        "tokenId": 1839,
        "owner": "0x23cf07579ff46aE8D500F59f597C28C7A4a1c517",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1840,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1841,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1842,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1843,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1844,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1845,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1846,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1847,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1848,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1849,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1850,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1851,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1852,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1853,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1854,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1855,
        "owner": "0x3C2C057c4736eAfc31Fc4D73cd900263D2765422",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 1856,
        "owner": "0x4f2a43CdE891346a259350581457EE3d4D4B3A5e",
        "reff": "0x92f751DD72c304dC688de0A8eD5819670039b0FB"
    },
    {
        "tokenId": 1857,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1858,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1859,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1860,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1861,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1862,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1863,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1864,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1865,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1866,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1867,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1868,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1869,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1870,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1871,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1872,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1873,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1874,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1875,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1876,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1877,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1878,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1879,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1880,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1881,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1882,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1883,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1884,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1885,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1886,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1887,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1888,
        "owner": "0xb37E460990aFFb31aA0C2D2e36DE11787214bC9E",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1889,
        "owner": "0xf50CA5006beAA480904Db989bC783B662958a9Dd",
        "reff": "0x313B917D2126a01ff073cb94882cFF2F6592bc98"
    },
    {
        "tokenId": 1890,
        "owner": "0xc88A35835422056D9fEb160277BF8563B5F3560d",
        "reff": "0x83189952ff2E503Bd69f3D1C5E17c68f1cCe3ba5"
    },
    {
        "tokenId": 1891,
        "owner": "0xc88A35835422056D9fEb160277BF8563B5F3560d",
        "reff": "0x83189952ff2E503Bd69f3D1C5E17c68f1cCe3ba5"
    },
    {
        "tokenId": 1892,
        "owner": "0x8d646a332E8755cAfdfF4eb758daB1E4B58c655D",
        "reff": "0x83189952ff2E503Bd69f3D1C5E17c68f1cCe3ba5"
    },
    {
        "tokenId": 1893,
        "owner": "0x8d646a332E8755cAfdfF4eb758daB1E4B58c655D",
        "reff": "0x83189952ff2E503Bd69f3D1C5E17c68f1cCe3ba5"
    },
    {
        "tokenId": 1894,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1895,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1896,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1897,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1898,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1899,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1900,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1901,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1902,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1903,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1904,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1905,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1906,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1907,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1908,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1909,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1910,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1911,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1912,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1913,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1914,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1915,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1916,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1917,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1918,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1919,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1920,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1921,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1922,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1923,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1924,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1925,
        "owner": "0x52FB3bb2BBDAA73a10870841ee532a8A47c71B83",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1926,
        "owner": "0x9109B45abD26502E4405BFD8b206E5cAB1fDbA30",
        "reff": "0x46f9662D4794BB170294953EA33F98D0ad635A4F"
    },
    {
        "tokenId": 1927,
        "owner": "0x9109B45abD26502E4405BFD8b206E5cAB1fDbA30",
        "reff": "0x46f9662D4794BB170294953EA33F98D0ad635A4F"
    },
    {
        "tokenId": 1928,
        "owner": "0x9109B45abD26502E4405BFD8b206E5cAB1fDbA30",
        "reff": "0x46f9662D4794BB170294953EA33F98D0ad635A4F"
    },
    {
        "tokenId": 1929,
        "owner": "0x9109B45abD26502E4405BFD8b206E5cAB1fDbA30",
        "reff": "0x46f9662D4794BB170294953EA33F98D0ad635A4F"
    },
    {
        "tokenId": 1930,
        "owner": "0x91FA7EE160796caf27D0dDbe9ee87A8198692692",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1931,
        "owner": "0x91FA7EE160796caf27D0dDbe9ee87A8198692692",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1932,
        "owner": "0x179e2b3377CD910B953b02b5fc51E0Af384b93dc",
        "reff": "0x8218AA7c5627888b71Ce378E1E8Bd1312f88D908"
    },
    {
        "tokenId": 1933,
        "owner": "0x8a850dB405aDD4B02EA5Dd882F3f7EAc254acc92",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 1934,
        "owner": "0x8a850dB405aDD4B02EA5Dd882F3f7EAc254acc92",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 1935,
        "owner": "0x91FA7EE160796caf27D0dDbe9ee87A8198692692",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1936,
        "owner": "0xF6CF6A33572143Acb0182CfFED21eDb3Ff48fe15",
        "reff": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6"
    },
    {
        "tokenId": 1937,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 1938,
        "owner": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1939,
        "owner": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1940,
        "owner": "0x4436A6B638Ea238B59c50B6972b29707f8e3a02C",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 1941,
        "owner": "0xD9F28C957b8b2deBd8165DB1C378d4525B1c1C93",
        "reff": "0xa54D5D80d3394D05cfc386CdD271c534F924e19E"
    },
    {
        "tokenId": 1942,
        "owner": "0xD9F28C957b8b2deBd8165DB1C378d4525B1c1C93",
        "reff": "0xa54D5D80d3394D05cfc386CdD271c534F924e19E"
    },
    {
        "tokenId": 1943,
        "owner": "0xD9F28C957b8b2deBd8165DB1C378d4525B1c1C93",
        "reff": "0xa54D5D80d3394D05cfc386CdD271c534F924e19E"
    },
    {
        "tokenId": 1944,
        "owner": "0x5ACd59237808047705f1992B1754390D376557e8",
        "reff": "0x761E9753d8A3826e99537FA8C3EEa5A5f9e6AEF3"
    },
    {
        "tokenId": 1945,
        "owner": "0x387D7737BA4Dce61D3FE9c1Ad22192b66e523a40",
        "reff": "0x08EaCF9C79994ba67CB36fF51C2124a4DeDad24c"
    },
    {
        "tokenId": 1946,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1947,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1948,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1949,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1950,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1951,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1952,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1953,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1954,
        "owner": "0xF015E4e54d9d103CA7D76E05b8B1Fef8F6E2BBBE",
        "reff": "0xF015E4e54d9d103CA7D76E05b8B1Fef8F6E2BBBE"
    },
    {
        "tokenId": 1955,
        "owner": "0x649431A2833c4EE6d6E3736ff3762a30f38e179A",
        "reff": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4"
    },
    {
        "tokenId": 1956,
        "owner": "0x649431A2833c4EE6d6E3736ff3762a30f38e179A",
        "reff": "0x91EF2f9702E1a9896d492A1e75848688cE235dB4"
    },
    {
        "tokenId": 1957,
        "owner": "0xc91DDEdC8137C467652AB8eBb82f35411D325753",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1958,
        "owner": "0xc91DDEdC8137C467652AB8eBb82f35411D325753",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1959,
        "owner": "0xc91DDEdC8137C467652AB8eBb82f35411D325753",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 1960,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x7266D83D745cd526AE797f3e24e11f34517B0898"
    },
    {
        "tokenId": 1961,
        "owner": "0x7266D83D745cd526AE797f3e24e11f34517B0898",
        "reff": "0x7266D83D745cd526AE797f3e24e11f34517B0898"
    },
    {
        "tokenId": 1962,
        "owner": "0x4FDbbD20E7EeB7812aaaB7A3B0362c427d714360",
        "reff": "0xEB5352021690c10eC3EF82c7203ca9B0a252e173"
    },
    {
        "tokenId": 1963,
        "owner": "0x4FDbbD20E7EeB7812aaaB7A3B0362c427d714360",
        "reff": "0xEB5352021690c10eC3EF82c7203ca9B0a252e173"
    },
    {
        "tokenId": 1964,
        "owner": "0x4FDbbD20E7EeB7812aaaB7A3B0362c427d714360",
        "reff": "0xEB5352021690c10eC3EF82c7203ca9B0a252e173"
    },
    {
        "tokenId": 1965,
        "owner": "0x4FDbbD20E7EeB7812aaaB7A3B0362c427d714360",
        "reff": "0xEB5352021690c10eC3EF82c7203ca9B0a252e173"
    },
    {
        "tokenId": 1966,
        "owner": "0x4FDbbD20E7EeB7812aaaB7A3B0362c427d714360",
        "reff": "0xEB5352021690c10eC3EF82c7203ca9B0a252e173"
    },
    {
        "tokenId": 1967,
        "owner": "0x4FDbbD20E7EeB7812aaaB7A3B0362c427d714360",
        "reff": "0xEB5352021690c10eC3EF82c7203ca9B0a252e173"
    },
    {
        "tokenId": 1968,
        "owner": "0x4FDbbD20E7EeB7812aaaB7A3B0362c427d714360",
        "reff": "0xEB5352021690c10eC3EF82c7203ca9B0a252e173"
    },
    {
        "tokenId": 1969,
        "owner": "0x4FDbbD20E7EeB7812aaaB7A3B0362c427d714360",
        "reff": "0xEB5352021690c10eC3EF82c7203ca9B0a252e173"
    },
    {
        "tokenId": 1970,
        "owner": "0x16bfEE6Dea6090D16aaD8c997aD09FFa44911308",
        "reff": "0x8218AA7c5627888b71Ce378E1E8Bd1312f88D908"
    },
    {
        "tokenId": 1971,
        "owner": "0xeFAf3B39C5E8cFCFfCb05376340F651CF55a210c",
        "reff": "0xf50CA5006beAA480904Db989bC783B662958a9Dd"
    },
    {
        "tokenId": 1972,
        "owner": "0xd9fa67773230DE9D671e5902b8e40c7051840d85",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1973,
        "owner": "0xd9fa67773230DE9D671e5902b8e40c7051840d85",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1974,
        "owner": "0xd9fa67773230DE9D671e5902b8e40c7051840d85",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 1975,
        "owner": "0xB2eDd43422167F93aD0ee19ae0195122cD0d73D8",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1976,
        "owner": "0x29B8D6AB7E20db4d0d4030CeA1eD01550A5B4F51",
        "reff": "0xEB5352021690c10eC3EF82c7203ca9B0a252e173"
    },
    {
        "tokenId": 1977,
        "owner": "0x25d511a3BD95e48Eb649858bfFf4134d3DaE95B0",
        "reff": "0x23cf07579ff46aE8D500F59f597C28C7A4a1c517"
    },
    {
        "tokenId": 1978,
        "owner": "0x0C598e57909817Af86af4d47202B363C0156d6E4",
        "reff": "0x23cf07579ff46aE8D500F59f597C28C7A4a1c517"
    },
    {
        "tokenId": 1979,
        "owner": "0x6fD82097FfB55e2C327Dbb3E66810A2cAAe4D700",
        "reff": "0xF015E4e54d9d103CA7D76E05b8B1Fef8F6E2BBBE"
    },
    {
        "tokenId": 1980,
        "owner": "0xE2BCE90552917e105C86Aa20f580995A64cf981f",
        "reff": "0xfed63E9374F8801F0b2A6e59F21D8de326cBC34A"
    },
    {
        "tokenId": 1981,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0x8218AA7c5627888b71Ce378E1E8Bd1312f88D908"
    },
    {
        "tokenId": 1982,
        "owner": "0x73fDBB140e604F20416e1324F6D8E97Da19e066d",
        "reff": "0xF4E4D51050DEaa936874A1F4E1217E5E14F21138"
    },
    {
        "tokenId": 1983,
        "owner": "0x8a2BFB4826447c7d7141d1637139570D6a8f6B50",
        "reff": "0xf50399fB21D52662eaEA457f17C8ACF6e0a0c06B"
    },
    {
        "tokenId": 1984,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1985,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1986,
        "owner": "0x38DBe7125F3861a98558c3e9F3E19395B99cA0Fe",
        "reff": "0xE2BCE90552917e105C86Aa20f580995A64cf981f"
    },
    {
        "tokenId": 1987,
        "owner": "0x747c78F46c90dAf860D6C093E669B10A7A52BC0a",
        "reff": "0x747c78F46c90dAf860D6C093E669B10A7A52BC0a"
    },
    {
        "tokenId": 1988,
        "owner": "0xaBCbbc2AB6f97647C176a07eFd485BD60026e95d",
        "reff": "0x747c78F46c90dAf860D6C093E669B10A7A52BC0a"
    },
    {
        "tokenId": 1989,
        "owner": "0x7A69b833c880F30008Cf8b9285e63b182F527927",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 1990,
        "owner": "0xB1dAdA378023aF6F6D4057F76e8f0295F9185D96",
        "reff": "0xeFAf3B39C5E8cFCFfCb05376340F651CF55a210c"
    },
    {
        "tokenId": 1991,
        "owner": "0x0C84F6A3E261711D836Abb0450EbbBa7628d5eaf",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 1992,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1993,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1994,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1995,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1996,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1997,
        "owner": "0x3dDCBB4FAbc1953E5A65a380fAb97573F77De04c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 1998,
        "owner": "0x263C7bE0C7Ae9d56B66b6e87221A230987213306",
        "reff": "0x2Ca2141f1578d6f66499349221F0eB3df4839aae"
    },
    {
        "tokenId": 1999,
        "owner": "0xe677dEB4bace5F1222cc37E1DB46D2f629388640",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 2000,
        "owner": "0x481011e0be83c91D2A3aC5fb4C2D5DFd3CF531b6",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2001,
        "owner": "0x378924D2b9eCf130E2749445f62c8C78Dc32f3Bb",
        "reff": "0x387D7737BA4Dce61D3FE9c1Ad22192b66e523a40"
    },
    {
        "tokenId": 2002,
        "owner": "0x6D8A94D46e0a2F1F501e85a7F3CE09093Df8065B",
        "reff": "0xa967da3b3CefeD637f3a921A50D8D01fA06D1205"
    },
    {
        "tokenId": 2003,
        "owner": "0xe737d88814d2f5270Fe84BCB9b7812156e06b4D5",
        "reff": "0x2F5Cf42362D8810Cd794deF73E44dFC18ca4b85e"
    },
    {
        "tokenId": 2004,
        "owner": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab",
        "reff": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d"
    },
    {
        "tokenId": 2005,
        "owner": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab",
        "reff": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d"
    },
    {
        "tokenId": 2006,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2007,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2008,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2009,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2010,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2011,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2012,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2013,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2014,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2015,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2016,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2017,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2018,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2019,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2020,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2021,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2022,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2023,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2024,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2025,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2026,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2027,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2028,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2029,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2030,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2031,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2032,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2033,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2034,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2035,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2036,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2037,
        "owner": "0x9AD04851265b99f13a35a84E4acf84B310E046f6",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2038,
        "owner": "0xA17D792335c4f9bf1e554F81aaea64bc683D493D",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 2039,
        "owner": "0xe04c909E7FD1EFE595929f693f17D4FAB2f27142",
        "reff": "0x1c5f0fe1F219dB6d3598E948d2c74644d7c2E247"
    },
    {
        "tokenId": 2040,
        "owner": "0xE1Ad29BCa5cCa37A589BC2BC07ee31FFF50a456a",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2041,
        "owner": "0xF015E4e54d9d103CA7D76E05b8B1Fef8F6E2BBBE",
        "reff": "0x6fD82097FfB55e2C327Dbb3E66810A2cAAe4D700"
    },
    {
        "tokenId": 2042,
        "owner": "0xF015E4e54d9d103CA7D76E05b8B1Fef8F6E2BBBE",
        "reff": "0x6fD82097FfB55e2C327Dbb3E66810A2cAAe4D700"
    },
    {
        "tokenId": 2043,
        "owner": "0xf78D84B59060B3818a883815813F9a67cae1Be82",
        "reff": "0x6D8A94D46e0a2F1F501e85a7F3CE09093Df8065B"
    },
    {
        "tokenId": 2044,
        "owner": "0xd9E948FbB00278c766eF1d17E019788443Ae418e",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2045,
        "owner": "0xd9E948FbB00278c766eF1d17E019788443Ae418e",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2046,
        "owner": "0x7b81C903F42717720F9055c960900F306a302746",
        "reff": "0x33eB5e58dBF9bF2A082C525B369aB7c2Ec320558"
    },
    {
        "tokenId": 2047,
        "owner": "0x7b81C903F42717720F9055c960900F306a302746",
        "reff": "0x33eB5e58dBF9bF2A082C525B369aB7c2Ec320558"
    },
    {
        "tokenId": 2048,
        "owner": "0x7b81C903F42717720F9055c960900F306a302746",
        "reff": "0x33eB5e58dBF9bF2A082C525B369aB7c2Ec320558"
    },
    {
        "tokenId": 2049,
        "owner": "0x7b81C903F42717720F9055c960900F306a302746",
        "reff": "0x33eB5e58dBF9bF2A082C525B369aB7c2Ec320558"
    },
    {
        "tokenId": 2050,
        "owner": "0x0a71D01c5A4A3c6b78717776D09D103D9084713F",
        "reff": "0xe04c909E7FD1EFE595929f693f17D4FAB2f27142"
    },
    {
        "tokenId": 2051,
        "owner": "0xe00E6041280EF93A0cBb146862aaC9360835a506",
        "reff": "0x1c5f0fe1F219dB6d3598E948d2c74644d7c2E247"
    },
    {
        "tokenId": 2052,
        "owner": "0x7E9222D02976634D004D8e956098db5FA8b89069",
        "reff": "0xE1Ad29BCa5cCa37A589BC2BC07ee31FFF50a456a"
    },
    {
        "tokenId": 2053,
        "owner": "0xD0789A37A2e442efe017B561C67C25713E6D9414",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2054,
        "owner": "0xD0789A37A2e442efe017B561C67C25713E6D9414",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2055,
        "owner": "0xb669a285933F1D82D2cFE6c76641A9ffc8F50cbC",
        "reff": "0x0a71D01c5A4A3c6b78717776D09D103D9084713F"
    },
    {
        "tokenId": 2056,
        "owner": "0xF015E4e54d9d103CA7D76E05b8B1Fef8F6E2BBBE",
        "reff": "0xF015E4e54d9d103CA7D76E05b8B1Fef8F6E2BBBE"
    },
    {
        "tokenId": 2057,
        "owner": "0xEb5483aD8b8b95b1377F7C6D40147209F20da8B3",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 2058,
        "owner": "0xEb5483aD8b8b95b1377F7C6D40147209F20da8B3",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 2059,
        "owner": "0xe107ee98785cd1418A551958C7D6936b9ec4cbcB",
        "reff": "0xAD172730134F132173961269BE13C1C5e95C5Eb2"
    },
    {
        "tokenId": 2060,
        "owner": "0x58F03DDb16bE75df015DF409D33360a800320855",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2061,
        "owner": "0x58F03DDb16bE75df015DF409D33360a800320855",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2062,
        "owner": "0x58F03DDb16bE75df015DF409D33360a800320855",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2063,
        "owner": "0x58F03DDb16bE75df015DF409D33360a800320855",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2064,
        "owner": "0x58F03DDb16bE75df015DF409D33360a800320855",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2065,
        "owner": "0x58F03DDb16bE75df015DF409D33360a800320855",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2066,
        "owner": "0x58F03DDb16bE75df015DF409D33360a800320855",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2067,
        "owner": "0x58F03DDb16bE75df015DF409D33360a800320855",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2068,
        "owner": "0x3CADbC2AAbC596B88eEb44AAc12e7721B1937bDb",
        "reff": "0x6014EA3b930Cd0cD2b8131A55a2b17f1229110B1"
    },
    {
        "tokenId": 2069,
        "owner": "0xCBdDFfaCF94b8A34B27cf928fdBf3A0Ff6D9b006",
        "reff": "0x3CADbC2AAbC596B88eEb44AAc12e7721B1937bDb"
    },
    {
        "tokenId": 2070,
        "owner": "0xfAEA32bbea91D39d41Be147E6c8F547848B99e44",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 2071,
        "owner": "0x8c50f8A3e34962D439FA15C63415A5698CC4EA0a",
        "reff": "0x179e2b3377CD910B953b02b5fc51E0Af384b93dc"
    },
    {
        "tokenId": 2072,
        "owner": "0x8c50f8A3e34962D439FA15C63415A5698CC4EA0a",
        "reff": "0x179e2b3377CD910B953b02b5fc51E0Af384b93dc"
    },
    {
        "tokenId": 2073,
        "owner": "0xE1Ad29BCa5cCa37A589BC2BC07ee31FFF50a456a",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2074,
        "owner": "0x9109B45abD26502E4405BFD8b206E5cAB1fDbA30",
        "reff": "0x9109B45abD26502E4405BFD8b206E5cAB1fDbA30"
    },
    {
        "tokenId": 2075,
        "owner": "0x9109B45abD26502E4405BFD8b206E5cAB1fDbA30",
        "reff": "0x9109B45abD26502E4405BFD8b206E5cAB1fDbA30"
    },
    {
        "tokenId": 2076,
        "owner": "0x9109B45abD26502E4405BFD8b206E5cAB1fDbA30",
        "reff": "0x9109B45abD26502E4405BFD8b206E5cAB1fDbA30"
    },
    {
        "tokenId": 2077,
        "owner": "0x9109B45abD26502E4405BFD8b206E5cAB1fDbA30",
        "reff": "0x9109B45abD26502E4405BFD8b206E5cAB1fDbA30"
    },
    {
        "tokenId": 2078,
        "owner": "0x01b1d834751e5E18e9a1CB61860EA7174E206191",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2079,
        "owner": "0x767d0dDfD122B81dfBC7D32901Ea1ef34C295B15",
        "reff": "0x7E9222D02976634D004D8e956098db5FA8b89069"
    },
    {
        "tokenId": 2080,
        "owner": "0x7E9222D02976634D004D8e956098db5FA8b89069",
        "reff": "0xE1Ad29BCa5cCa37A589BC2BC07ee31FFF50a456a"
    },
    {
        "tokenId": 2081,
        "owner": "0x7E9222D02976634D004D8e956098db5FA8b89069",
        "reff": "0xE1Ad29BCa5cCa37A589BC2BC07ee31FFF50a456a"
    },
    {
        "tokenId": 2082,
        "owner": "0xfADa49D449bD524ad1D3E0726389282BE9D6b878",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 2083,
        "owner": "0x8146c89ea2d49D8b9A5BBe81BEF5f93840A8ab40",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2084,
        "owner": "0x8146c89ea2d49D8b9A5BBe81BEF5f93840A8ab40",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2085,
        "owner": "0xe737d88814d2f5270Fe84BCB9b7812156e06b4D5",
        "reff": "0xe737d88814d2f5270Fe84BCB9b7812156e06b4D5"
    },
    {
        "tokenId": 2086,
        "owner": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2087,
        "owner": "0xB2eDd43422167F93aD0ee19ae0195122cD0d73D8",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 2088,
        "owner": "0x063686e317afC558F20FFeAd76E93B8FE3A2AC66",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2089,
        "owner": "0xe737d88814d2f5270Fe84BCB9b7812156e06b4D5",
        "reff": "0xe737d88814d2f5270Fe84BCB9b7812156e06b4D5"
    },
    {
        "tokenId": 2090,
        "owner": "0xcF8bdADE28E27FFeB34a9d0b68CaF324F7637df0",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2091,
        "owner": "0xcF8bdADE28E27FFeB34a9d0b68CaF324F7637df0",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2092,
        "owner": "0xcF8bdADE28E27FFeB34a9d0b68CaF324F7637df0",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2093,
        "owner": "0xcF8bdADE28E27FFeB34a9d0b68CaF324F7637df0",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2094,
        "owner": "0xcF8bdADE28E27FFeB34a9d0b68CaF324F7637df0",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2095,
        "owner": "0xcF8bdADE28E27FFeB34a9d0b68CaF324F7637df0",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2096,
        "owner": "0xcF8bdADE28E27FFeB34a9d0b68CaF324F7637df0",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2097,
        "owner": "0xcF8bdADE28E27FFeB34a9d0b68CaF324F7637df0",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2098,
        "owner": "0xcF8bdADE28E27FFeB34a9d0b68CaF324F7637df0",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2099,
        "owner": "0xcF8bdADE28E27FFeB34a9d0b68CaF324F7637df0",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2100,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2101,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2102,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2103,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2104,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2105,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2106,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2107,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2108,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2109,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2110,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2111,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2112,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2113,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2114,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2115,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2116,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2117,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2118,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2119,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2120,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2121,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2122,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2123,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2124,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2125,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2126,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2127,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2128,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2129,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2130,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2131,
        "owner": "0xF867EEb098AdD36F27e64D7fcae3e82F140ae96D",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2132,
        "owner": "0x64AbF2d9E4437EF3549ed48E7725Dcbc025F34c3",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2133,
        "owner": "0x64AbF2d9E4437EF3549ed48E7725Dcbc025F34c3",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2134,
        "owner": "0x64AbF2d9E4437EF3549ed48E7725Dcbc025F34c3",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2135,
        "owner": "0x64AbF2d9E4437EF3549ed48E7725Dcbc025F34c3",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2136,
        "owner": "0x8c50f8A3e34962D439FA15C63415A5698CC4EA0a",
        "reff": "0x8c50f8A3e34962D439FA15C63415A5698CC4EA0a"
    },
    {
        "tokenId": 2137,
        "owner": "0x8c50f8A3e34962D439FA15C63415A5698CC4EA0a",
        "reff": "0x8c50f8A3e34962D439FA15C63415A5698CC4EA0a"
    },
    {
        "tokenId": 2138,
        "owner": "0x8c50f8A3e34962D439FA15C63415A5698CC4EA0a",
        "reff": "0x8c50f8A3e34962D439FA15C63415A5698CC4EA0a"
    },
    {
        "tokenId": 2139,
        "owner": "0x8c50f8A3e34962D439FA15C63415A5698CC4EA0a",
        "reff": "0x8c50f8A3e34962D439FA15C63415A5698CC4EA0a"
    },
    {
        "tokenId": 2140,
        "owner": "0xa69cC9a28B0C715e82Dc5190bcD7D2D51da889F8",
        "reff": "0x7266D83D745cd526AE797f3e24e11f34517B0898"
    },
    {
        "tokenId": 2141,
        "owner": "0x3d0E245B9f85af6251be4425f8211f1253d8053B",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2142,
        "owner": "0xDa37c273e99f4C3caEF8eD18BbaC72618786c46d",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2143,
        "owner": "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2144,
        "owner": "0xa1534E54A390dAd3e6EdA03891F114412E6b1c4A",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2145,
        "owner": "0xA43AC7477Ab29bc7442d89b69842b6A7da72C685",
        "reff": "0xacDa8b6120e221a1C4dd288A88Ed9E6c88247649"
    },
    {
        "tokenId": 2146,
        "owner": "0xA43AC7477Ab29bc7442d89b69842b6A7da72C685",
        "reff": "0xacDa8b6120e221a1C4dd288A88Ed9E6c88247649"
    },
    {
        "tokenId": 2147,
        "owner": "0x789a35C6Ff7b0ddE3C21852a3B9F82bf1A22CCfB",
        "reff": "0x7E5916b06A467bA986C6B20e0E59803915fc5851"
    },
    {
        "tokenId": 2148,
        "owner": "0x0D72904c1AEa0638F27F735008558120D8838b4c",
        "reff": "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F"
    },
    {
        "tokenId": 2149,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2150,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2151,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2152,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2153,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2154,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2155,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2156,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2157,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2158,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2159,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2160,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2161,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2162,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2163,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2164,
        "owner": "0x7195940583CF71e2c59b2254bA144c058Af3b00f",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2165,
        "owner": "0x56F54C1aC430774EaD5F9ab726F204cB9045C05f",
        "reff": "0xbc1EBBFeFE0E9515638DA09649474b06281D9156"
    },
    {
        "tokenId": 2166,
        "owner": "0x9f7FE4b18357B2D91B160023CD6A9824c4fC4C86",
        "reff": "0xf50CA5006beAA480904Db989bC783B662958a9Dd"
    },
    {
        "tokenId": 2167,
        "owner": "0x1Ef6b3AD0ED83C194b9b5407AF39b91C0fe3c1f1",
        "reff": "0x001bcBABE13532dA342575A24F5e1dDC014884f0"
    },
    {
        "tokenId": 2168,
        "owner": "0x946b7c6c8F0C6cfAdD32C2fae15B8464458AAe70",
        "reff": "0x789a35C6Ff7b0ddE3C21852a3B9F82bf1A22CCfB"
    },
    {
        "tokenId": 2169,
        "owner": "0x946b7c6c8F0C6cfAdD32C2fae15B8464458AAe70",
        "reff": "0x789a35C6Ff7b0ddE3C21852a3B9F82bf1A22CCfB"
    },
    {
        "tokenId": 2170,
        "owner": "0xb4b127bdF975A4c083A38d7c423C4941AD731214",
        "reff": "0x7195940583CF71e2c59b2254bA144c058Af3b00f"
    },
    {
        "tokenId": 2171,
        "owner": "0xb4b127bdF975A4c083A38d7c423C4941AD731214",
        "reff": "0x7195940583CF71e2c59b2254bA144c058Af3b00f"
    },
    {
        "tokenId": 2172,
        "owner": "0xcC240114321854291EAe1CbC6116a410d4faf76F",
        "reff": "0xE1Ad29BCa5cCa37A589BC2BC07ee31FFF50a456a"
    },
    {
        "tokenId": 2173,
        "owner": "0xcC240114321854291EAe1CbC6116a410d4faf76F",
        "reff": "0xE1Ad29BCa5cCa37A589BC2BC07ee31FFF50a456a"
    },
    {
        "tokenId": 2174,
        "owner": "0x9730A32e93F49C789d7cCC36580c210FfE93b4f9",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2175,
        "owner": "0x9730A32e93F49C789d7cCC36580c210FfE93b4f9",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2176,
        "owner": "0x9730A32e93F49C789d7cCC36580c210FfE93b4f9",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2177,
        "owner": "0x9730A32e93F49C789d7cCC36580c210FfE93b4f9",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2178,
        "owner": "0x9730A32e93F49C789d7cCC36580c210FfE93b4f9",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2179,
        "owner": "0x9730A32e93F49C789d7cCC36580c210FfE93b4f9",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2180,
        "owner": "0x9730A32e93F49C789d7cCC36580c210FfE93b4f9",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2181,
        "owner": "0x9730A32e93F49C789d7cCC36580c210FfE93b4f9",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2182,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2183,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2184,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2185,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2186,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2187,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2188,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2189,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2190,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2191,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2192,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2193,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2194,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2195,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2196,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2197,
        "owner": "0x0ca8e8E1A0683d496aF14E55bA974B14E28ffd2C",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2198,
        "owner": "0x0E93B8BAc279C5f629B6EC7ae5C630D1272572C4",
        "reff": "0xcC240114321854291EAe1CbC6116a410d4faf76F"
    },
    {
        "tokenId": 2199,
        "owner": "0x0E93B8BAc279C5f629B6EC7ae5C630D1272572C4",
        "reff": "0xcC240114321854291EAe1CbC6116a410d4faf76F"
    },
    {
        "tokenId": 2200,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2201,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2202,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2203,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2204,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2205,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2206,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2207,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2208,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2209,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2210,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2211,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2212,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2213,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2214,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2215,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2216,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2217,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2218,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2219,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2220,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2221,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2222,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2223,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2224,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2225,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2226,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2227,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2228,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2229,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2230,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2231,
        "owner": "0x2f504735DA0b120A926AB5D709e4Bb82f588B343",
        "reff": "0x9AD04851265b99f13a35a84E4acf84B310E046f6"
    },
    {
        "tokenId": 2232,
        "owner": "0x703E426c1272fE75Df2d28be7477A723D0e416Ea",
        "reff": "0x9f7FE4b18357B2D91B160023CD6A9824c4fC4C86"
    },
    {
        "tokenId": 2233,
        "owner": "0x111Ef6E42A5C95B01C6329f988F8e2B5896e8aA5",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 2234,
        "owner": "0x111Ef6E42A5C95B01C6329f988F8e2B5896e8aA5",
        "reff": "0x222E10A0160471a309D0B75B471CB0D0e6c50475"
    },
    {
        "tokenId": 2235,
        "owner": "0x64AbF2d9E4437EF3549ed48E7725Dcbc025F34c3",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2236,
        "owner": "0x64AbF2d9E4437EF3549ed48E7725Dcbc025F34c3",
        "reff": "0x960E2d55dB08d6fDb0f9e89563d9Bcd8bD529211"
    },
    {
        "tokenId": 2237,
        "owner": "0xfD677C62138F88Db0F8722776378aD5e4b100e94",
        "reff": "0x7195940583CF71e2c59b2254bA144c058Af3b00f"
    },
    {
        "tokenId": 2238,
        "owner": "0xfD677C62138F88Db0F8722776378aD5e4b100e94",
        "reff": "0x7195940583CF71e2c59b2254bA144c058Af3b00f"
    },
    {
        "tokenId": 2239,
        "owner": "0x2D87F0eD4A1fcddd9b20DD1d65350aeCABa7AB1f",
        "reff": "0x703E426c1272fE75Df2d28be7477A723D0e416Ea"
    },
    {
        "tokenId": 2240,
        "owner": "0x8d604c3619E954B611A1EF6533af01dD9fD60110",
        "reff": "0xF015E4e54d9d103CA7D76E05b8B1Fef8F6E2BBBE"
    },
    {
        "tokenId": 2241,
        "owner": "0xDBeA362B97a8b8BCD2E3D0142C658Bf74FBb4852",
        "reff": "0x8218AA7c5627888b71Ce378E1E8Bd1312f88D908"
    },
    {
        "tokenId": 2242,
        "owner": "0x4376Ac70655f5B0E61e70EEe12C7F78b1C656d0C",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 2243,
        "owner": "0x4376Ac70655f5B0E61e70EEe12C7F78b1C656d0C",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 2244,
        "owner": "0x4376Ac70655f5B0E61e70EEe12C7F78b1C656d0C",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 2245,
        "owner": "0x4376Ac70655f5B0E61e70EEe12C7F78b1C656d0C",
        "reff": "0xd2835f467EF52d748a100d0F120F60A9E0aAd797"
    },
    {
        "tokenId": 2246,
        "owner": "0x8146c89ea2d49D8b9A5BBe81BEF5f93840A8ab40",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2247,
        "owner": "0x8146c89ea2d49D8b9A5BBe81BEF5f93840A8ab40",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2248,
        "owner": "0x8146c89ea2d49D8b9A5BBe81BEF5f93840A8ab40",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2249,
        "owner": "0x8146c89ea2d49D8b9A5BBe81BEF5f93840A8ab40",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2250,
        "owner": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2251,
        "owner": "0xbcD63b61445FacB0ADFF6aD7428D06CdB2F97379",
        "reff": "0x946b7c6c8F0C6cfAdD32C2fae15B8464458AAe70"
    },
    {
        "tokenId": 2252,
        "owner": "0xbcD63b61445FacB0ADFF6aD7428D06CdB2F97379",
        "reff": "0x946b7c6c8F0C6cfAdD32C2fae15B8464458AAe70"
    },
    {
        "tokenId": 2253,
        "owner": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab",
        "reff": "0x7F73A275f063C9F2Fc2de3F276b5297d38c1E5Ab"
    },
    {
        "tokenId": 2254,
        "owner": "0xECd4DB8972d52a07EcE45fb90E509c6ADe1591Ae",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2255,
        "owner": "0x9fd24517f390DD6b06C0246ec6e6332A239af208",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 2256,
        "owner": "0x9fd24517f390DD6b06C0246ec6e6332A239af208",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 2257,
        "owner": "0x9fd24517f390DD6b06C0246ec6e6332A239af208",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 2258,
        "owner": "0x9fd24517f390DD6b06C0246ec6e6332A239af208",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 2259,
        "owner": "0xA840a0515040f4C31E27E0854D567f93a08de050",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 2260,
        "owner": "0xA840a0515040f4C31E27E0854D567f93a08de050",
        "reff": "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56"
    },
    {
        "tokenId": 2261,
        "owner": "0xefb7e1CEe5B308f16D3477d82b2f40f1aFd9FB12",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 2262,
        "owner": "0xefb7e1CEe5B308f16D3477d82b2f40f1aFd9FB12",
        "reff": "0x551EdD56609e7a1f8243993fAc321e33AaF140b4"
    },
    {
        "tokenId": 2263,
        "owner": "0x809D42349f0140101F08cc04397E46C804d46290",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 2264,
        "owner": "0x809D42349f0140101F08cc04397E46C804d46290",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 2265,
        "owner": "0x809D42349f0140101F08cc04397E46C804d46290",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 2266,
        "owner": "0x809D42349f0140101F08cc04397E46C804d46290",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 2267,
        "owner": "0x809D42349f0140101F08cc04397E46C804d46290",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 2268,
        "owner": "0x809D42349f0140101F08cc04397E46C804d46290",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 2269,
        "owner": "0x809D42349f0140101F08cc04397E46C804d46290",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 2270,
        "owner": "0x809D42349f0140101F08cc04397E46C804d46290",
        "reff": "0x6A6D41Ba0a7b99bB3FcBdCd7F00de7619D2bD233"
    },
    {
        "tokenId": 2271,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2272,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2273,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2274,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2275,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2276,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2277,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2278,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2279,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2280,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2281,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2282,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2283,
        "owner": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4",
        "reff": "0x8d8651e62a71A134F3439149bf36a37069ADB4b4"
    },
    {
        "tokenId": 2284,
        "owner": "0x4Be6fac8870CFd7D518A256dfa2031a50e78fa15",
        "reff": "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6"
    },
    {
        "tokenId": 2285,
        "owner": "0x720d8b1c4c7E54dA2D05dB0258e55EF5791d8DBC",
        "reff": "0xEB5352021690c10eC3EF82c7203ca9B0a252e173"
    },
    {
        "tokenId": 2286,
        "owner": "0x91C162E62C20f27AB526879f5A9d16d8c77D67b9",
        "reff": "0x83B83F5F8143CfeC675e47919b091337286cDE5B"
    },
    {
        "tokenId": 2287,
        "owner": "0x91C162E62C20f27AB526879f5A9d16d8c77D67b9",
        "reff": "0x83B83F5F8143CfeC675e47919b091337286cDE5B"
    },
    {
        "tokenId": 2288,
        "owner": "0x91C162E62C20f27AB526879f5A9d16d8c77D67b9",
        "reff": "0x83B83F5F8143CfeC675e47919b091337286cDE5B"
    },
    {
        "tokenId": 2289,
        "owner": "0x28E763dD0Db0Afe1B70481EF738D1B939E53afaD",
        "reff": "0x946b7c6c8F0C6cfAdD32C2fae15B8464458AAe70"
    },
    {
        "tokenId": 2290,
        "owner": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5",
        "reff": "0x6a180056BeDD00028be3f8d11B2aDEd4ea47d003"
    },
    {
        "tokenId": 2291,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2292,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2293,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2294,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2295,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2296,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2297,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2298,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2299,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2300,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2301,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2302,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2303,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2304,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2305,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2306,
        "owner": "0xba342570411beF4b044ea40E63E04aD832c0ea54",
        "reff": "0x7B7707d39aD2F9c3b6BF0Bf7bf1757cFc855E3B5"
    },
    {
        "tokenId": 2307,
        "owner": "0xa2e8C88643ae3Da166C3F4afCb7B47E11a08B91f",
        "reff": "0x001F51255c84061F9aB88739710EaAD6DC3b81B7"
    },
    {
        "tokenId": 2308,
        "owner": "0x58F03DDb16bE75df015DF409D33360a800320855",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2309,
        "owner": "0x58F03DDb16bE75df015DF409D33360a800320855",
        "reff": "0x511C645389eBe73aecFfaA57924d14ec46c13de8"
    },
    {
        "tokenId": 2310,
        "owner": "0x2F5Cf42362D8810Cd794deF73E44dFC18ca4b85e",
        "reff": "0x2F5Cf42362D8810Cd794deF73E44dFC18ca4b85e"
    },
    {
        "tokenId": 2311,
        "owner": "0xAf8d9dF3e8d50063509227A02073f667Dd27300B",
        "reff": "0x532878F750Db65Aba73879b45BEb30eD986ae1e3"
    }
];


  const [loading, setLoading] = useState(false);
  const [txMessage, setTxMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successCopyToken, setSuccessCopyToken] = useState(false);

  const navigate = useNavigate();


  const copyTokenAddress = () => {
    navigator.clipboard.writeText(SETTINGS.tokenAddress)
      .then(() => {
        setSuccessCopyToken(true);
        // Optionally, reset the success message after a timeout
        setTimeout(() => setSuccessCopyToken(false), 3000);
      })
      .catch(err => console.error('Failed to copy text:', err));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(refCode)
      .then(() => {
        setSuccess(true);
        // Optionally, reset the success message after a timeout
        setTimeout(() => setSuccess(false), 3000);
      })
      .catch(err => console.error('Failed to copy text:', err));
  };

  async function getBalance() {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    try {
      const USDTContract = new Contract(
        SETTINGS.usdtAddress,
        ERC20_ABI,
        signer
      );
      const USDTBalance = await USDTContract.balanceOf(address);
      const USDTDecimals = await USDTContract.decimals();
      setUsdtBalance(formatUnits(USDTBalance, USDTDecimals));

      const TokenContract = new Contract(
        SETTINGS.tokenAddress,
        ERC20_ABI,
        signer
      );
      const TokenBalance = await TokenContract.balanceOf(address);
      const TokenDecimals = await TokenContract.decimals();
      setTokenBalance(parseFloat(formatUnits(TokenBalance, TokenDecimals)));
    } catch (error) {
      console.log(error);
    }
  }
  async function getReferral() {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    
    try {
      // Create contract instances for new and old contracts
      const nodeContract = new Contract(
        SETTINGS.nodeContract,
        NodesABI,
        signer
      );
      
      const nodeContractOld = new Contract(
        SETTINGS.nodeContractOld,
        NodesABI,
        signer
      );
      
      // Try to get referral code from the new contract
      let code = await nodeContract.inviteCodeForAddress(address);
      
      // If the code is missing or invalid, fall back to the old contract
      if (!code) { 
        code = await nodeContractOld.inviteCodeForAddress(address);
      }
      
      // Set the referral code, either from the new or old contract
      setRefCode(code);
  
    } catch (error) {
      console.error("Error fetching referral code:", error);
    }
  }

  const getReferredTokens = async () => {
    try {
      setLoading(true);
     
      setReferredTokenIds([]);

      const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();

    const nodeContract = new Contract(
        SETTINGS.nodeContract,
        NodesABI,
        signer
      );

      // Call exportData() from the contract
      const [tokenIds, mintTimes, lastClaims, referrers] = await nodeContract.exportData();

      // Filter out the token IDs where `referrers[i]` matches the user's address
      const referredIDs = tokenIds.filter((tokenId, index) => {
        return referrers[index].toLowerCase() === address.toLowerCase();
      });
      console.log("referredIDs");
      console.log(referredIDs);
      setReferredTokenIds(referredIDs);
    } catch (err) {
      
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  

  const fetchTokenData = async () => {

    
    let tempArr = [];
    for(let i=0;i<refferals.length;i++){
        const item = refferals[i];
    


        let reff = item["reff"];
        if(reff.toLowerCase() == address.toLowerCase()){
          tempArr.push({
            tokenId:item["tokenId"],
            owner: item["owner"],
            reff: item["reff"],
            rew: getReffReward(item["tokenId"]),
          });
        };
    }
    console.log(tempArr);
    setRefArr(tempArr);
  };

  const getReffReward = (_tknId) => {
    if(_tknId < 500){
      return 30;
    }
    if(_tknId < 2000){
      return 45;
    }
    if(_tknId < 3000){
      return 60;
    }
    if(_tknId < 4000){
      return 80;
    }

  };
  
  const fetchTokenData1 = async () => {
   setRefLoading(true);

    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    
    try {
      console.log('Fetching data from contract...');
      const nodeContract = new Contract(SETTINGS.nodeContractOld, NodesABI, signer);
      const nodeContractNew = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const collectionContract = new Contract(SETTINGS.collection, CollectionABI, signer);

      // Call the exportData function
      const minted = await collectionContract.minted();
      console.log(minted); 

      let tempArr = [];
      let tempReffAmount = 0;
      let userAddress = address;
      let prevAddr = "";
      for(let i=1;i<minted;i++){
        const ownerAddress = await collectionContract.ownerOf(i);
        console.log(ownerAddress);
        
        let refby = "-";
        if(i >1396){
           refby = await nodeContractNew.referredBy(i);
        }else{
           refby = await nodeContract.referredBy(i);
        }
        const t = {
          "tokenId":i,
          "owner": ownerAddress,
          "reff": refby
        };
        console.log(t);
        tempArr.push(t);
        
      }
      console.log(tempArr);
      const fileName = `reff_export.json`;
      const jsonString = JSON.stringify(tempArr, null, 2);

      // Create a blob with JSON MIME type
      const blob = new Blob([jsonString], { type: "application/json" });
      
      // Generate a URL for the blob
      const url = window.URL.createObjectURL(blob);
      
      // Create an anchor element to initiate download
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      
      // Trigger the download
      a.click();
      
      // Revoke the object URL to free up resources
      window.URL.revokeObjectURL(url);
      setRefArr(tempArr);


    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setRefLoading(false);
    }
   
  };



  const importTokenToMetamask = async () => {
    const tokenAddress = SETTINGS.tokenAddress;
    const tokenSymbol = SETTINGS.tokenSymbol;
    const tokenDecimals = 18;
    const tokenImage = "https://node1.spacemonkeez.io/logoToken.png";

    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });
    } catch (error) {
      console.log("Error importing token to Metamask:", error);
    }
  };

  const openBuyToken = () => {
    navigate("/buy-token");
  };

  const openStakeToken = () => {
    navigate("/staking");
  };
  const openBuyNode = () => {
    navigate("/nodes");
  };

  const claimRewards = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();

    try {
      setLoading(true);
      setTxMessage("Claiming rewards...");

      // Connect to the NFT contract and claim rewards
      const nftContract = new Contract(SETTINGS.nodeContract, NodesABI, signer);

      // Call the claimRewards function on the NFT contract
      const buyTx = await nftContract.claimAllRewards();
      await buyTx.wait();

      setTxMessage("Rewards claimed!");
    } catch (error) {
      console.error("Error claiming rewards:", error);
      setTxMessage("Failed to claim rewards. Please try again.");
    } finally {
      await fetchOwnedNFTs();
      setLoading(false);
      setTimeout(() => setTxMessage(""), 5000);
    }
  };

  useEffect(() => {
    getBalance();
    getReferral();
  }, [provider, address]);

  const renderOwnedNFTs = () => {
    return ownedNFTs.map((nft, index) => (
      <Node
        key={index}
        provider={provider}
        account={address}
        collectionAddress={nft.collectionAddress}
        tokenId={nft.tokenId}
        unclaimedRewards={nft.unclaimedRewards}
        name={nft.name}
        imageURL={nft.imageURL}
      />
    ));
  };

  if (loading) {
    return (
        <div className="loaderScreen text-center">
            <br />
            <Spinner animation="border" role="status" className='loaderBig' /><br />

            <p className='loaderMsg'>{txMessage}</p>
        </div>
    );
}

  return (
    <>
      <div className="">
        <div className="buy-token-header"></div>
      </div>

     


{isConn && refCode != "" && (
<Card className="pokemon-card mb-3">
      <Card.Body>
      <Card.Title>Refferal</Card.Title>
      <Row className="mb-3">
      <Col className="col-12 col-md-6 mb-3">
      {refArr.length == 0 ?(
        <Button
          
        onClick={fetchTokenData}
        className="buton"
      >
       Load referral info
      </Button>
      ):(
        <>
       
        
      
        </>
      )}
        
        
        </Col>
    
      
   
      <Col className="col-12 col-md-6">
      <Button onClick={handleCopy} className="buton">
      {success && "Code copied successfully!"}
      {!success && "Copy code: " + refCode}
      </Button>

      </Col>
      <Col className="col-12 mt-3">
      <div className="table-responsive">
  <table className="table table-striped table-bordered">
    <thead>
      <tr>
        <th>Tkn. ID</th>
        <th>User</th>
        <th>Reward</th>
      </tr>
    </thead>
    <tbody>
      {refArr.map((item, index) => (
        <tr key={index}>
          <td>{item["tokenId"]}</td>
          <td>{item["owner"]}</td>
          <td>{item["rew"]} USDC</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

      </Col>
      
      </Row>
        
        </Card.Body>
        </Card>
)}
      <div>
        <div className="balanceDisplay pokemon-card width-on-mobile">
          <img src={boxImg} />

          {ownedNFTs.length}
          <Button
            variant="primary"
            onClick={openBuyNode}
            className="buton smallBtn"
          >
            <small style={{ fontSize: "12px" }}>BUY NODE</small>
          </Button>
        </div>
        <div className="balanceDisplay pokemon-card width-on-mobile">
          <img src={logoToken} />

          {tokenBalance.toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })}
          <small> {SETTINGS.tokenSymbol} </small>

     
          <Button
            variant="primary"
            onClick={copyTokenAddress}
            className="buton smallBtn show-on-mobile"
          >

{successCopyToken && "Success!"}
{!successCopyToken && "Copy "+SETTINGS.tokenSymbol+" address"}
            
          </Button>
          <Button
            variant="primary"
            onClick={importTokenToMetamask}
            className="buton smallBtn hide-on-mobile"
          >
            Import to Metamask
          </Button>
          {/* 
               <Button variant="primary" onClick={openStakeToken}  className='buton smallBtn'>
           Stake
       </Button>
    */}
        </div>

        <div className="balanceDisplay pokemon-card width-on-mobile">
          <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png" />

          {usdtBalance}
          <small> USDC </small>
        </div>
      </div>
      <Row className="mb-3">
        <Col>
          <div>
            <hr />

            <div>
              <Row>
                <Col sm={12} md={6} lg={4} xl={3} className="mb-4">
                  <Card className="nft-card h-100 pokemon-card">
                    {/*   <Card.Img variant="top" src={boxImg} /> */}
                    <div className="iframe-container">
                      <iframe
                        src={SETTINGS.modelUrl}
                        title="External Content"
                      />
                    </div>

                    <Card.Body>
                      <Card.Title>
                        <h6 className="pokemon-card-title">
                          {" "}
                          {SETTINGS.nodeName}
                        </h6>
                      </Card.Title>
                      <div className="card-text">
                        <h5
                          className="text-center"
                          style={{ marginBottom: "10px", marginTop: "30px" }}
                        >
                          <small>
                            Total unclaimed:
                            <br />{" "}
                          </small>
                          {totalRewards}
                        </h5>
                        <Button
                        style={{ marginBottom: "10px", marginTop: "10px" }}
                          variant="primary"
                          onClick={claimRewards}
                          className="buton"
                        >
                          Claim
                        </Button>
                        <Row>
                          {/*
        <Col xs={6}>
        <EtherscanTransactions provider={provider} account={address}  />
        </Col>
        */}
                          <Col xs={6}>
                            <Button
                              variant="primary"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  SETTINGS.collection
                                );
                              }}
                              className="buton smallBtn"
                            >
                              <small style={{ fontSize: "12px" }}>
                                {trunAddress}
                              </small>{" "}
                              <img
                                src={copyImg}
                                style={{ width: "13px", marginLeft: "5px" }}
                              />
                            </Button>
                          </Col>
                          <Col xs={6}>
                            <Button
                              variant="primary"
                              onClick={openBuyNode}
                              className="buton smallBtn"
                            >
                              <small style={{ fontSize: "12px" }}>
                                Buy node
                              </small>{" "}
                              <img
                                src={addImg}
                                style={{ width: "13px", marginLeft: "5px" }}
                              />
                            </Button>
                          </Col>
                        </Row>

                      
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={6} lg={8} xl={9} className="mb-4">
                  {renderOwnedNFTs()}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
