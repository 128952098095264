import React, { useEffect, useState } from "react";


function DexScreener({ provider, address, isConn }) {
  

  return (
    <>
     <div id="dexscreener-embed">
        <iframe src="https://dexscreener.com/avalanche/0xc13B1c627dac6B3767224e5F8880E154619469F1?embed=1&loadChartSettings=0&chartLeftToolbar=0&chartDefaultOnMobile=1&chartTheme=dark&theme=dark&chartStyle=0&chartType=usd&interval=15">
        </iframe></div>
    </>
  );
}

export default DexScreener;
